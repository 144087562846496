import { Box, TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';
import MainCard from '../../components/Common/MainCard';
//

export const OverviewWrapper = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2),

  [theme.breakpoints.up('lg')]: {
    margin: theme.spacing(5),
  },
}));

export const PageBoxWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2, 0, 2),

  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(0, 5, 0, 5),
  },
}));

export const MainCardStyled = styled(MainCard)(({ theme }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  [theme.breakpoints.up('sm')]: {
    backgroundColor: theme.palette.grey.darker,
  },
}));

export const TableCellHead = styled(TableCell)(({ theme }) => ({
  // border: "none",
  backgroundColor: theme.palette.grey.darker,
  borderBottom: `1px solid ${theme.palette.grey.dark}`,
  color: theme.palette.common.black,
  ...theme.typography.Body_medium_14,
  [theme.breakpoints.up('sm')]: {
    ...theme.typography.Body_medium_16,
  },
}));

export const TableCellData = styled(TableCell)(({ theme }) => ({
  // border: "none",
  borderBottom: `1px solid ${theme.palette.grey.dark}`,
  color: theme.palette.common.black,
  ...theme.typography.Body_medium_14,
}));
