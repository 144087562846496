import { all, call, fork, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes"
import { userForgetPasswordError, userForgetPasswordSuccess } from "./actions"

//Include Both Helper File with needed methods
import { postJwtForgetPwd } from '../../../helpers/backend_helper';

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    
      const response = yield call(postJwtForgetPwd, {
        email: user.email,
      })
      if (response.codes === 200) {
        yield put(
          userForgetPasswordSuccess(
            response.message
          )
        )
      }else {
        yield put(
          userForgetPasswordError(
            response.message
          )
        )
      }
  } catch (error) {
    yield put(userForgetPasswordError(error))
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
