import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, Col, Spinner } from 'reactstrap';
import JwtService from '../../helpers/jwtService';
import { BUY_PLAN } from '../../helpers/url_helper';

const CardPricing = (props) => {
  const { setAlert, setAlertMessage } = props;
  const [loading, setLoader] = useState(false);
  const history = useHistory();
  const buyPlan = (id) => {
    let userId = parseInt(localStorage.getItem('userId'));
    setLoader(true);
    JwtService.post(BUY_PLAN, { planId: id, businessId: userId }).then(
      (res) => {
        setLoader(false);
        if (res.codes === 200) {
          setAlertMessage({
            variant: 'success',
            message: res.message,
          });
          setAlert(true);
          localStorage.removeItem('userId');
          setTimeout(() => {
            history.push('/login');
          }, 2000);
        } else {
          setAlertMessage({
            variant: 'danger',
            message: res.message,
          });
          setAlert(true);
          localStorage.removeItem('userId');
        }
      }
    );
  };

  return (
    <React.Fragment>
      <Col xl="4">
        <Card className="pricing-box text-center">
          <CardBody className="p-4">
            <div>
              <div className="mt-3">
                <h5 className="mb-1">{props.pricing.planName}</h5>
                {/* <p className="text-muted">{props.pricing.description}</p> */}
              </div>

              {/* <div className="py-3">
                <i className={"h1 text-primary uil uil-" + props.pricing.icon}></i>
              </div> */}
            </div>
            <ul className="list-unstyled plan-features mt-3">
              {props.pricing.features.map((feature, key) => (
                <li key={'_feature_' + key}>{feature.title}</li>
              ))}
            </ul>

            <div className="py-4">
              <h3>
                {new Intl.NumberFormat('ja-JP', {
                  style: 'currency',
                  currency: 'GBP',
                }).format(props.pricing.planPrice)}
              </h3>
            </div>

            <div className="text-center plan-btn my-2">
              {loading ? (
                <Button
                  to="#"
                  className="btn btn-primary waves-effect waves-light"
                  disabled={loading}
                >
                  {' '}
                  <span>
                    <Spinner className="spinner-border-sm mr-5" color="white" />{' '}
                    Buying...
                  </span>
                </Button>
              ) : (
                <Button
                  to="#"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => buyPlan(props.pricing.id)}
                >
                  Buy Now
                </Button>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

CardPricing.propTypes = {
  pricing: PropTypes.object,
};

export default CardPricing;
