import React, { useEffect, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";

// third-party
import ReactApexChart from "react-apexcharts";

// chart options
const columnChartOptions = {
  chart: {
    type: "bar",
    height: 450,
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "50%",
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  xaxis: {
    categories: [],
  },
  yaxis: {
    title: {
      text: "$ (thousands)",
    },
  },
  fill: {
    opacity: 1,
  },
  tooltip: {
    y: {
      formatter(val) {
        return `${val}`;
      },
    },
  },
  legend: {
    show: true,
    position: "top",
    offsetX: 10,
    offsetY: 10,
    labels: {
      useSeriesColors: false,
    },
    markers: {
      width: 16,
      height: 16,
      radius: 5,
    },
    itemMargin: {
      horizontal: 15,
      vertical: 8,
    },
  },
  responsive: [
    {
      breakpoint: 600,
      options: {
        yaxis: {
          show: true,
        },
      },
    },
  ],
};

// ==============================|| COLUMN CHART ||============================== //

const ApexColumnChart = ({ apexIncomeData, apexExpenseData, apexMonth }) => {
  const theme = useTheme();

  const { primary } = theme.palette.text;
  const darkLight = theme.palette.grey.dark;

  const gridLine = theme.palette.primary.chart;

  const firstCol = theme.palette.orange.main;
  const secondCol = theme.palette.primary.chart;

  const [series, setSeries] = useState([]);
  useEffect(() => {
    if (apexExpenseData.length && apexIncomeData.length) {
      let array = [
        {
          name: "Total Income",
          data: apexIncomeData,
        },
        {
          name: "Total Expense",
          data: apexExpenseData,
        },
      ];
      setSeries(array);
    }
  }, [apexIncomeData, apexExpenseData, apexMonth]);

  const [options, setOptions] = useState(columnChartOptions);

  React.useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: [firstCol, secondCol],
      xaxis: {
        labels: {
          style: {
            colors: [
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
            ],
          },
        },
        categories: apexMonth,
      },
      yaxis: {
        labels: {
          style: {
            colors: [primary],
          },
        },
      },

      grid: {
        borderColor: gridLine,
        border: "2px solid",
        xaxis: {
          lines: {
            show: true, //or just here to disable only x axis grids
          },
        },
        yaxis: {
          lines: {
            show: true, //or just here to disable only y axis
          },
        },
      },
      tooltip: {
        // enabled: false,
        theme: "dark",
      },
      legend: {
        labels: {
          colors: "grey.500",
        },
      },
      chart: {
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
      },
    }));
  }, [primary, darkLight, firstCol, secondCol, gridLine, apexMonth]);

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={450}
      />
    </div>
  );
};

export default ApexColumnChart;
