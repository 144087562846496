import PropTypes from "prop-types";
import { Popover } from "@mui/material";

// ----------------------------------------------------------------------

MenuPopover.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
};

export default function MenuPopover({ children, sx, ...other }) {
  return (
    <Popover
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      PaperProps={{
        sx: {
          mt: 1.5,
          ml: 0.5,
          overflow: "inherit",
          backgroundColor: (theme) => theme.palette.common.white,
          boxShadow: "0px 10px 20px 2px rgb(11,11,11)",
          border: "none",
          width: 200,
          padding: "0.5em 0.5em 0.5em",
          ...sx,
        },
      }}
      {...other}
    >
      {children}
    </Popover>
  );
}
