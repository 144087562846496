import React from "react";

const StyledCircularProgress = (props) => {
  let {
    size = 100,
    progressValue = "",
    type = "",
    selectMonth = "",
    trackWidth = 6,
    trackColor = `#f0f0f0`,
    indicatorWidth = 6,
    indicatorCap = `round`,
    labelColor = `#000`,
    fontSize,
  } = props;
  const viewBox = `0 0 ${size} ${size}`;
  const center = size / 2,
    radius =
      center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth),
    dashArray = 2 * Math.PI * radius,
    dashOffset = dashArray * ((100 - progressValue) / 100);

  return (
    <>
      {" "}
      <div
        className="svg-pi-wrapper"
        style={{
          width: size,
          height: size,
          position: "relative",
        }}
      >
        {type !== "noCirculation" && (
          <>
            {" "}
            <svg
              className="svg-pi"
              style={{ width: size, height: size }}
              viewBox={viewBox}
            >
              <circle
                cx={center}
                cy={center}
                fill="transparent"
                r={radius}
                stroke={trackColor}
                strokeWidth={trackWidth}
              />
              <circle
                cx={center}
                cy={center}
                fill="transparent"
                r={radius}
                stroke={props.indicatorColor}
                strokeWidth={indicatorWidth}
                strokeDasharray={dashArray}
                strokeDashoffset={dashOffset}
                strokeLinecap={indicatorCap}
              />
            </svg>
          </>
        )}

        <div className="svg-pi-label" style={{ color: labelColor }}>
          <span
            className="svg-pi-label__progress"
            style={{ fontSize: fontSize }}
          >
            {type !== "noCirculation"
              ? type === "percentage"
                ? `${props.progressValue}%`
                : props.progressValue
              : `${props.progressValue} / ${selectMonth || 1}`}
          </span>
        </div>
      </div>
    </>
  );
};

export default StyledCircularProgress;
