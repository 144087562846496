import { call, put, takeEvery } from "redux-saga/effects";

// Ecommerce Redux States
import {
  GET_CLIENTS
} from "./actionTypes";
import {
  getOrdersFail,
  getOrdersSuccess
} from "./actions";

//Include Both Helper File with needed methods
import { getClients } from '../../helpers/backend_helper';

function* fetchClients() {
  try {
    const response = yield call(getClients);
    if (response.code === 200) {
      yield put(getOrdersSuccess(response.data));
    }
  } catch (error) {
    yield put(getOrdersFail(error));
  }
}


function* clientSaga() {
  yield takeEvery(GET_CLIENTS, fetchClients);
}

export default clientSaga;
