import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Dialog,
  IconButton,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import {
  // matchRoutes,
  NavLink as RouterLink,
  useHistory,
  useLocation,
} from "react-router-dom";
import Logo from "../../../assets/images/Logo.svg";
import SuccessImg from "../../../assets/images/Success.png";
import MonthlyAffordability from "../../../pages/dashboard-ui/Tabs/MonthlyAffordability";
import MonthlyExpenses from "../../../pages/dashboard-ui/Tabs/MonthlyExpenses";
import MonthlyIncome from "../../../pages/dashboard-ui/Tabs/MonthlyIncome";
import MyAccount from "./MyAccount";

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 95;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  minHeight: APPBAR_MOBILE,
  zIndex: 99,
  backgroundColor: theme.palette.common.white,

  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
  },
}));

const BoxWrap = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backgroundColor: "transparent",
  display: "flex",
  width: "100%",
  position: "absolute",
  bottom: 0,
  justifyContent: "center",
  padding: theme.spacing(0, 5),
}));

const MainStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const HeaderItemsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(1),

  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    gap: theme.spacing(4),
  },
}));

const UserNameText = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_semibold_20,
  color: theme.palette.common.black,
  [theme.breakpoints.up("sm")]: {
    ...theme.typography.Body_semibold_24,
  },
}));

// ----------------------------------------------------------------------

export default function DashboardUINav({ dashboardData, location, consent }) {
  const history = useHistory();
  const [basicAffordability, setBasicAffordability] = useState(false);
  const [fullAffordability, setFullAffordability] = useState(false);
  const [statement, setStatement] = useState(false);
  const [pof, setPof] = useState(false);
  const [kyc, setKyc] = useState(false);

  const [openIncome, setOpenIncome] = useState(false);

  const handleCloseIncomeDialog = () => {
    setOpenIncome(false);
  };

  const [openExpenses, setOpenExpenses] = useState(false);
  const handleCloseExpensesDialog = () => {
    setOpenExpenses(false);
  };

  const [openAffordability, setOpenAffordability] = useState(false);
  const handleCloseAffordabilityDialog = () => {
    setOpenAffordability(false);
  };

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  useEffect(() => {
    let allProducts = [];
    consent?.forEach((v) => {
      if (v?.consent?.data?.products.length && v?.consent?.data?.products) {
        allProducts.push(v?.consent?.data?.products);
      }
    });
    allProducts = allProducts.flat(2);
    allProducts?.filter((item) => {
      if (item.code === "affordability_basic") {
        setBasicAffordability(true);
      }
      if (item.code === "affordability_full") {
        setFullAffordability(true);
        setBasicAffordability(true);
        setStatement(true);
      }
      if (
        item.code === "statement_basic" ||
        (item.code === "statement_plus" && item?.months?.length)
      ) {
        setStatement(true);
      }
      if (item.code === "kyc" && item?.months?.length) {
        setKyc(true);
      }
      if (item.code === "pof" && item?.months?.length) {
        setPof(true);
      }
    });
  }, [consent]);

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);


  const theme = useTheme();

  const isActiveRoot = useLocation();

  const ListItemStyle = styled((props) => <ListItemButton {...props} />)(
    ({ theme }) => ({
      ...theme.typography.Body_medium_14,
      position: "relative",
      textAlign: "center",
      textTransform: "initial",
      padding: theme.spacing(2, 1),
      margin: theme.spacing(0.5, 1),
      color: theme.palette.common.black,
      borderBottom: `2px solid transparent`,
      maxHeight: 66,
    })
  );
  const activeRootStyle = {
    color: theme.palette.orange.main,
    borderBottom: `2px solid ${theme.palette.orange.main}`,
    "&:before": { display: "block" },
    "&:hover": {
      color: theme.palette.orange.main,
    },
  };
  const handleRiskRedirect = () => {
    history.push("/risk-and-insight", { ...location?.state });
  };
  const handleCategoryRedirect = () => {
    history.push("/category-insights", { ...location?.state });
  };
  const handleDashboardRedirect = () => {
    history.push("/dashboard-overview", { ...location?.state });
  };
  const handleStatmentRedirect = () => {
    history.push("/statement", { ...location?.state });
  };
  const handleProofOfFundsRedirect = () => {
    history.push("/proof-of-funds", { ...location?.state });
  };
  const handleIncomeAndJobVerificationRedirect = () => {
    history.push("/kyc", { ...location?.state });
  };

  const displayDesktop = () => {
    return (
      <>
        <BoxWrap>
          <MainStyle>
            {userDetails}
            {headerItems}
          </MainStyle>
        </BoxWrap>
      </>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));
    return (
      <>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          p={2}
        >
          <Box component="img" src={Logo} alt="Logo" width={94} height={41} />

          <Stack direction="row" alignItems="center">
            <IconButton
              sx={{
                display: { lg: "none" },
              }}
            >
              <KeyboardArrowDownIcon
                sx={{ fill: theme.palette.orange.main }}
                fontSize="large"
              />
            </IconButton>

            <IconButton
              {...{
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                sx={{ fill: theme.palette.orange.main }}
                fontSize="large"
              />
            </IconButton>
          </Stack>
        </Stack>

        <Dialog
          {...{
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
          PaperProps={{
            sx: { width: "100%" },
          }}
        >
          <Stack direction="column" alignItems="center" gap={3}>
            <Box
              py={4}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderBottom: "1px solid #002E45",
              }}
            >
              <MyAccount />
            </Box>

            {headerItems}
          </Stack>
        </Dialog>

        {isActiveRoot.pathname === "/dashboard-overview" && (
          <Box m={4}>{userDetails}</Box>
        )}
      </>
    );
  };

  const userDetails = (
    <Stack direction="row" alignItems="center">
      <Box component={RouterLink} to="/client-list" sx={{ color: "black" }}>
        <ChevronLeftIcon sx={{ cursor: "pointer" }} />
      </Box>

      <Stack
        direction="column"
        alignItems="start"
        justifyContent="center"
        ml={2}
      >
        <Stack direction="row" gap={1} alignItems="center">
          <UserNameText>{location?.state?.clientName}</UserNameText>
          <Box component="img" src={SuccessImg} />
        </Stack>
        {/*  <UserInfo >
                    Amoueq Oscae, Open banking date fetched 04, Aug 2022 . 3:15
                  </UserInfo>*/}
      </Stack>
    </Stack>
  );

  const headerItems = (
    <HeaderItemsWrapper>
      {basicAffordability && (
        <>
          <ListItemStyle
            // to="/dashboard-overview"
            // component={RouterLink}
            onClick={handleDashboardRedirect}
            sx={{
              ...(isActiveRoot.pathname === '/dashboard-overview' &&
                activeRootStyle),
            }}
          >
            Overview
          </ListItemStyle>
        </>
      )}
      {fullAffordability && (
        <>
          <ListItemStyle
            onClick={handleRiskRedirect}
            // to="/risk-and-insight"
            // component={RouterLink}
            sx={{
              ...(isActiveRoot.pathname === '/risk-and-insight' &&
                activeRootStyle),
            }}
          >
            Risk
          </ListItemStyle>
          <ListItemStyle
            onClick={handleCategoryRedirect}
            // to="/category-insights"
            // component={RouterLink}
            sx={{
              ...(isActiveRoot.pathname === '/category-insights' &&
                activeRootStyle),
            }}
          >
            Category Insights
          </ListItemStyle>
        </>
      )}
      {statement && (
        <ListItemStyle
          onClick={handleStatmentRedirect}
          // to="/statement"
          // component={RouterLink}
          sx={{
            ...(isActiveRoot.pathname === '/statement' && activeRootStyle),
          }}
        >
          Statement
        </ListItemStyle>
      )}
      {kyc && (
        <ListItemStyle
          onClick={handleIncomeAndJobVerificationRedirect}
          sx={{
            ...(isActiveRoot.pathname === '/kyc' && activeRootStyle),
          }}
        >
          KYC
        </ListItemStyle>
      )}
      {pof && (
        <ListItemStyle
          onClick={handleProofOfFundsRedirect}
          // to="/statement"
          // component={RouterLink}
          sx={{
            ...(isActiveRoot.pathname === '/proof-of-funds' && activeRootStyle),
          }}
        >
          Proof of Funds
        </ListItemStyle>
      )}
      {/* <Typography variant="Body_medium_14">Transactions</Typography>
              <AnimateButton>
                <Button
                  endIcon={<ArrowDropDownIcon />}
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{ width: "100%" }}
                >
                  Export
                </Button>
              </AnimateButton>{" "} */}
    </HeaderItemsWrapper>
  );

  return (
    <>
      <RootStyle>
        <>{mobileView ? displayMobile() : displayDesktop()}</>
      </RootStyle>

      <MonthlyIncome
        open={openIncome}
        dashboardData={dashboardData}
        handleCloseDialog={handleCloseIncomeDialog}
      />
      <MonthlyExpenses
        open={openExpenses}
        dashboardData={dashboardData}
        handleCloseDialog={handleCloseExpensesDialog}
      />
      <MonthlyAffordability
        open={openAffordability}
        dashboardData={dashboardData}
        handleCloseDialog={handleCloseAffordabilityDialog}
      />
    </>
  );
}
