import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Autocomplete,
  Box,
  Collapse,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../components/Common/Loader';
import {
  BoxWrapper,
  MainStyle,
  RootStyle,
} from '../../components/Common/Style';
import { getUserData } from '../../components/Common/common';
import DashboardUINav from '../../components/Navbar/DashboardUINav/DashboardUINav';
import { getConsent, getKYC } from '../../helpers/backend_helper';
import {
  BoxCardTable,
  CompanyDetails,
  IncomeCardHead,
  IncomePageWrapperMain,
  IncomePageWrapperTop,
  IncomeTable,
  ListWrapper,
  MatrixTable,
  SmallBoxCard,
} from './IncomeAndJobVerificationStyle';
import styles from './styles.module.css';

const monthRange = [
  {
    title: '3 Months',
    value: 3,
  },
  {
    title: '6 Months',
    value: 6,
  },
  {
    title: '9 Months',
    value: 9,
  },
  {
    title: '12 Months',
    value: 12,
  },
];
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
const IncomeAndJobVerification = () => {
  const history = useHistory();
  const location = useLocation();
  const [dashboardData] = useState();
  const [filletElement, setFilletElement] = useState([]);
  const [selectedDateRang, setSelectedDateRang] = useState([]);
  const [selectedEndDate, setSelectedEndDate] = useState();

  const [companyDetailData, setCompanyDetailData] = useState();
  const [loading, setLoading] = useState(false);

  const [endDate, setEndDate] = useState();
  const [selectAccount, setSelectAccount] = useState();
  const [metrics, setMetrics] = useState();
  const [transactionInfo, setTransactionInfo] = useState();
  const [accountsNumberList, setAccountsNumberList] = useState([]);
  const [filterTransactionInfo, setFilterTransactionInfo] = useState([]);
  const [topTransaction, setTopTransection] = useState({
    inflow: [],
    outFlow: [],
  });
  const [topPaymentSME, setTopPaymentSME] = useState({
    inflow: [],
    outFlow: [],
  });
  const [topPaymentLME, setTopPaymentLME] = useState({
    inflow: [],
    outFlow: [],
  });
  const [bottomTransaction, setBottomTransection] = useState({
    inflow: [],
    outFlow: [],
  });

  const [topInBound, setTopInBound] = useState(false);
  const [topOutBound, setTopOutBound] = useState(false);
  const [bottomInBound, setBottomInBound] = useState(false);
  const [selectMonth, setSelectMonth] = useState();
  const [bottomOutBound, setBottomOutBound] = useState(false);
  const [top5InBoundPaymentSME, setTop5InBoundPaymentSME] = useState(false);
  const [top5OutBoundPaymentSME, setTop5OutBoundPaymentSME] = useState(false);
  const [top5InBoundPaymentLME, setTop5InBoundPaymentLME] = useState(false);
  const [top5OutBoundPaymentLME, setTop5OutBoundPaymentLME] = useState(false);
  const [financialMatrics, setFinancialMatrics] = useState(false);
  const [selectedConsent, setSelectedConsent] = useState({});

  const [isVerification] = useState({
    isAddressVerification: true,
    isBankingVerification: true,
    isCustomerVerification: true,
  });

  const [consent, setConsent] = useState();
  useEffect(async () => {
    let endDate = moment().format('YYYY-MM-DD');
    setLoading(true);
    await getConsent(location)
      .then((res) => {
        if (res.codes == 200) {
          if (res.data?.length) {
            let accountNumberList = [];
            res?.data.map((v) => {
              if (
                v.consent.data.products.filter((item) => item.code === 'kyc')
                  .length
              )
                if (v.accountNumber) {
                  accountNumberList.push({
                    title: `XXXXXX${v.accountNumber.slice(-4)}`,
                    value: v.accountNumber,
                  });
                }
            });
            if (!accountNumberList || !accountNumberList.length) {
              history.push('/proof-of-funds', { ...location?.state });
            }

            let selectedProduct = res?.data?.find(
              (item) => item.accountNumber === selectAccount
            );

            if (selectedProduct && selectAccount?.length) {
              endDate = moment(selectedProduct?.consent?.data?.dateTo).format(
                'YYYY-MM-DD'
              );
              let dateRangArray = [
                {
                  startDate: selectedProduct?.consent?.data?.dateFrom,
                  endDate: selectedProduct?.consent?.data?.dateTo,
                },
              ];
              calculateDateRang(dateRangArray, setSelectedDateRang);
            } else {
              let dateRangArray = res?.data.map((v) => {
                return {
                  startDate: v.consent?.data?.dateFrom,
                  endDate: v.consent?.data?.dateTo,
                };
              });
              let allEndDate = res?.data.map((v) => v.consent?.data?.dateTo);
              let maxEndDate =
                allEndDate.length &&
                allEndDate.reduce(function (a, b) {
                  return a > b ? a : b;
                });

              calculateDateRang(dateRangArray, setSelectedDateRang);
              endDate = moment(maxEndDate).format('YYYY-MM-DD');
            }
            setEndDate(endDate);
            setConsent(res?.data);
          }
        } else if (res?.codes === 404) {
          history.push('/login');
        } else {
          setLoading(false);
          toast.error(
            res?.response?.data?.message ||
              res.message ||
              'Something went wrong!'
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error('Something went wrong!');
      });
  }, [selectMonth, selectAccount, selectedEndDate]);
  useEffect(async () => {
    let userData = getUserData();
    let endDate = moment().format('YYYY-MM-DD');
    setLoading(true);
    await getConsent(location)
      .then((res) => {
        if (res.codes == 200) {
          if (res.data?.length) {
            let accountNumberList = [];
            res?.data.map((v) => {
              if (
                v.consent.data.products.filter(
                  (item) =>
                    item.code === 'affordability_basic' ||
                    item.code === 'affordability_full'
                ).length
              )
                if (v.accountNumber) {
                  accountNumberList.push({
                    title: `XXXXXX${v.accountNumber.slice(-4)}`,
                    value: v.accountNumber,
                  });
                }
            });
            if (!accountNumberList.length) {
              navigator('/dashboard-overview');
            }

            let selectedProduct = res?.data?.find(
              (item) => item.accountNumber === selectAccount
            );

            if (selectedProduct && selectAccount?.length) {
              endDate = moment(selectedProduct?.consent?.data?.dateTo).format(
                'YYYY-MM-DD'
              );
              let dateRangArray = [
                {
                  startDate: selectedProduct?.consent?.data?.dateFrom,
                  endDate: selectedProduct?.consent?.data?.dateTo,
                },
              ];
              calculateDateRang(dateRangArray, setSelectedDateRang);
              setSelectedConsent(selectedProduct);
            } else {
              let dateRangArray = res?.data.map((v) => {
                return {
                  startDate: v.consent?.data?.dateFrom,
                  endDate: v.consent?.data?.dateTo,
                };
              });
              let allEndDate = res?.data.map((v) => v.consent?.data?.dateTo);
              let maxEndDate =
                allEndDate.length &&
                allEndDate.reduce(function (a, b) {
                  return a > b ? a : b;
                });

              calculateDateRang(dateRangArray, setSelectedDateRang);
              endDate = moment(maxEndDate).format('YYYY-MM-DD');
            }
            setEndDate(endDate);
            setAccountsNumberList(accountNumberList);
            setConsent(res?.data);
          }
        } else if (res?.codes === 404) {
          history.push('/login');
        } else {
          setLoading(false);
          toast.error(
            res?.response?.data?.message ||
              res.message ||
              'Something went wrong!'
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error('Something went wrong!');
      });
  }, [selectMonth, selectAccount, selectedEndDate]);
  useEffect(async () => {
    setLoading(true);
    let dataArray = [];
    let data = selectedDateRang.length;
    monthRange.forEach((v, index) => {
      if (data >= v.value) {
        dataArray.push(v);
      }
    });
    let dateToValue = selectMonth
      ? [...filletElement, ...selectedDateRang].find((item) => {
          if (selectedEndDate) {
            return item.value === selectedEndDate;
          } else {
            return item.value === selectMonth;
          }
        })
      : filletElement[filletElement.length - 1];
    let payload = {
      userId: location?.state?.client,
      date_to:
        moment(dateToValue?.title).format('MMMM - YYYY') !== 'Invalid date'
          ? dateToValue?.value
          : endDate,
      n_months:
        moment(dateToValue?.title).format('MMMM - YYYY') !== 'Invalid date'
          ? 1
          : selectMonth || dataArray[dataArray.length - 1].value,
      account_id: selectAccount || null,
    };
    if (payload.userId && payload.date_to && payload.n_months) {
      await getKYC(payload).then((res) => {
        if (res?.codes === 200) {
          setLoading(false);
          setMetrics(res?.data?.kycDetails?.monthly);
          setCompanyDetailData(res?.data?.companyDetails);
          setTransactionInfo(res?.data?.kycDetails);
          setTopTransection({
            inFlow: res?.data.kycDetails.top_5_inflows,
            outFlow: res?.data.kycDetails.top_5_outflows,
          });
          setBottomTransection({
            inFlow: res?.data.kycDetails.bottom_5_inflows,
            outFlow: res?.data.kycDetails.bottom_5_outflows,
          });
          setTopPaymentSME({
            inFlow: res?.data.kycDetails.small_business_inflows,
            outFlow: res?.data.kycDetails.small_business_outflows,
          });
          setTopPaymentLME({
            inFlow: res?.data.kycDetails.large_business_inflows,
            outFlow: res?.data.kycDetails.large_business_outflows,
          });
        }
      });
    }
    setFilletElement(dataArray);
  }, [selectedDateRang, endDate]);
  useEffect(() => {
    let obj = {
      isSalary: false,
      isDividends: false,
      businessIncome: false,
      investments: false,
      others: false,
    };
    transactionInfo?.data?.length &&
      transactionInfo?.data.forEach((item) => {
        if (item?.category?.match('Business Income')) {
          obj = { ...obj, businessIncome: false };
        } else if (item?.category?.match('Salary')) {
          obj = { ...obj, isSalary: true };
        } else if (item?.category?.match('Dividend')) {
          obj = { ...obj, isDividends: true };
        } else {
          obj = { ...obj, others: true };
        }
      });
    setFilterTransactionInfo(transactionInfo?.data);
  }, [transactionInfo]);

  return (
    <RootStyle>
      <DashboardUINav
        location={location}
        dashboardData={dashboardData}
        consent={consent}
      />
      <MainStyle>
        {loading ? (
          <Loader />
        ) : (
          <BoxWrapper>
            <Stack
              direction="row"
              flexWrap="wrap"
              alignItems="center"
              justifyContent="space-between"
              marginBottom={'30px'}
            >
              <Box sx={{ flexGrow: 1 }} />

              <Box sx={{ flexGrow: 2, mt: { sm: 0, xs: 5 } }}>
                <Grid container gap={2} justifyContent="flex-end">
                  <Grid item md={2.5} xs={12}>
                    <Typography variant="Body_medium_16" color="orange.main">
                      Account Number
                    </Typography>
                    <Autocomplete
                      id="select-business-partner"
                      options={accountsNumberList}
                      getOptionLabel={(option) => option?.title}
                      // PaperComponent={CustomPaper}
                      value={accountsNumberList?.find(
                        (item) => item.value === selectAccount
                      )}
                      renderInput={(params) => (
                        <TextField
                          variant="filled"
                          id="filled-hidden-label-normal"
                          placeholder="Select..."
                          inputProps={{ 'aria-label': 'Without label' }}
                          hiddenLabel
                          fullWidth
                          {...params}
                        />
                      )}
                      onChange={(e, value) => {
                        setSelectAccount(value?.value || null);
                      }}
                    />
                  </Grid>

                  <Grid item md={2.5} xs={12}>
                    <Typography variant="Body_medium_16" color="orange.main">
                      Filter Period
                    </Typography>
                    <Autocomplete
                      id="select-business-partner"
                      options={[...filletElement, ...selectedDateRang]}
                      getOptionLabel={(option) => {
                        if (
                          moment(option?.title).format('MMMM - YYYY') !==
                          'Invalid date'
                        ) {
                          return moment(option?.title).format('MMMM - YYYY');
                        } else {
                          return option?.title;
                        }
                      }}
                      value={
                        selectMonth
                          ? [...filletElement, ...selectedDateRang].find(
                              (item) => {
                                if (selectedEndDate) {
                                  return item.value === selectedEndDate;
                                } else {
                                  return item.value === selectMonth;
                                }
                              }
                            )
                          : filletElement[filletElement.length - 1]
                      }
                      renderInput={(params) => (
                        <TextField
                          variant="filled"
                          id="filled-hidden-label-normal"
                          placeholder="Select..."
                          inputProps={{ 'aria-label': 'Without label' }}
                          hiddenLabel
                          fullWidth
                          {...params}
                        />
                      )}
                      onChange={(e, newData) => {
                        setSelectMonth(newData?.value);
                        setSelectedEndDate(newData?.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Stack>
            <IncomePageWrapperMain>
              <IncomePageWrapperTop>
                <SmallBoxCard>
                  <div>
                    <IncomeCardHead>CAC Registration</IncomeCardHead>
                  </div>

                  <CompanyDetails>
                    <ul>
                      <li>Company Name:- {companyDetailData?.approvedName}</li>
                      <li>
                        Nature Of Business:-{' '}
                        {companyDetailData?.natureOfBusinessName}
                      </li>
                      <li>
                        Company Address:- {companyDetailData?.address},
                        {companyDetailData?.city},{companyDetailData?.state}
                      </li>
                      <li>Company Number:- {companyDetailData?.rcNumber}</li>
                      <li>
                        Company Status:-{' '}
                        {companyDetailData?.active ? 'Active' : 'Inactive'}
                      </li>
                      <li>
                        Date Of Creation:-{' '}
                        {companyDetailData?.registrationDate &&
                          moment(companyDetailData?.registrationDate).format(
                            'YYYY-MM-DD'
                          )}
                      </li>
                      <li>No Of Directors :- </li>
                      <li>PEP List :- </li>
                      <li>Filing History :- </li>
                      <li>Other companies directors are linked to :- </li>
                      <li>Debt owed :- </li>
                      <li>Capacity to borrow :- </li>
                    </ul>
                  </CompanyDetails>
                </SmallBoxCard>

                <SmallBoxCard>
                  <Box mt={0}>
                    <div>
                      <IncomeCardHead>Verification</IncomeCardHead>
                    </div>

                    <Box ml={0}>
                      <ListWrapper className={styles.list}>
                        <input
                          type="checkbox"
                          id="BankAccountVerification"
                          name="Bank account verification"
                          checked={isVerification?.isBankingVerification}
                        />
                        <label htmlFor="BankAccountVerification">
                          Bank account
                        </label>
                      </ListWrapper>

                      <ListWrapper className={styles.list}>
                        <input
                          type="checkbox"
                          id="CustomerVerification"
                          name="Customer Verification"
                          checked={isVerification?.isCustomerVerification}
                        />
                        <label htmlFor="CustomerVerification">
                          Customer Verification
                        </label>
                      </ListWrapper>

                      <ListWrapper className={styles.list}>
                        <input
                          type="checkbox"
                          id="AddressVerification"
                          name="Address Verification"
                          checked={isVerification?.isAddressVerification}
                        />
                        <label htmlFor="AddressVerification">
                          Company Details
                        </label>
                      </ListWrapper>
                    </Box>
                  </Box>
                </SmallBoxCard>
              </IncomePageWrapperTop>

              <IncomePageWrapperTop>
                <SmallBoxCard>
                  <div>
                    <IncomeCardHead>Risk Insights</IncomeCardHead>
                  </div>
                  <CompanyDetails>
                    <ul>
                      <li>Sanctioned List :- </li>
                      <Box ml={2} display="flex" flexDirection="column">
                        <span>a. Transactions to Sanctioned Country List</span>
                        <span>b. Transactions to High-risk countries</span>
                      </Box>
                      <li>Other Risks :- </li>
                    </ul>
                  </CompanyDetails>
                </SmallBoxCard>

                <SmallBoxCard>
                  <div>
                    <div
                      onClick={() => setFinancialMatrics(!financialMatrics)}
                      aria-expanded={financialMatrics}
                      aria-label="show more"
                      style={{
                        cursor: 'pointer',
                        marginBottom: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                        padding: '8px',
                      }}
                    >
                      <IncomeCardHead>Financial Metrics</IncomeCardHead>

                      <ExpandMore
                        expand={financialMatrics}
                        onClick={() => setFinancialMatrics(!financialMatrics)}
                        aria-expanded={financialMatrics}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </div>

                    <Collapse
                      in={financialMatrics}
                      timeout="auto"
                      unmountOnExit
                    >
                      <MatrixTable>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Monthly Recurring Revenue (MRR)</th>
                            <th>MMR Growth Rate (MoM)</th>
                            <th>Monthly Burn Rate</th>
                            <th>Monthly Salary Payments</th>
                            <th>No of unique transactions</th>
                            <th>Payment to Directors</th>
                          </tr>
                        </thead>
                        <tbody>
                          {metrics && metrics.length ? (
                            <>
                              {metrics.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <div style={{ width: '80px' }}>
                                      {item?.month &&
                                        moment(item?.month).format('MMM-YY')}
                                    </div>
                                  </td>
                                  <td>
                                    {' '}
                                    ₦{' '}
                                    {(item?.revenue || 0).toLocaleString(
                                      undefined,
                                      {
                                        maximumFractionDigits: 2,
                                      }
                                    )}
                                  </td>
                                  <td>
                                    {' '}
                                    {(item?.growth_rate || 0).toLocaleString(
                                      undefined,
                                      {
                                        maximumFractionDigits: 2,
                                      }
                                    )}{' '}
                                    %
                                  </td>
                                  <td>
                                    ₦{' '}
                                    {(item?.burn_rate || 0).toLocaleString(
                                      undefined,
                                      {
                                        maximumFractionDigits: 2,
                                      }
                                    )}
                                  </td>
                                  <td></td>
                                  <td>{item?.unique_customer_number}</td>
                                  <td></td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </MatrixTable>
                    </Collapse>
                  </div>
                  <div>
                    <div
                      onClick={() => setTopInBound(!topInBound)}
                      aria-expanded={topInBound}
                      aria-label="show more"
                      style={{
                        cursor: 'pointer',
                        marginBottom: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                        padding: '8px',
                      }}
                    >
                      <IncomeCardHead>Top 5 Inflows</IncomeCardHead>

                      <ExpandMore
                        expand={topInBound}
                        onClick={() => setTopInBound(!topInBound)}
                        aria-expanded={topInBound}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </div>

                    <Collapse in={topInBound} timeout="auto" unmountOnExit>
                      <MatrixTable
                        style={{
                          height: '450px',
                          margin: 0,
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th align="center">INBOUND/OUTBOUND</th>
                            <th align="center">Description</th>
                            <th align="center">Date</th>
                            <th align="center">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {topTransaction && topTransaction?.inFlow?.length ? (
                            <>
                              {topTransaction?.inFlow.map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>INBOUND</td>
                                  <td>{item?.description}</td>
                                  <td>
                                    {item?.date &&
                                      moment(item?.date).format('DD-MMM-YYYY')}
                                  </td>
                                  <td>
                                    ₦{' '}
                                    {(item?.amount || 0).toLocaleString(
                                      undefined,
                                      {
                                        maximumFractionDigits: 2,
                                      }
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </MatrixTable>
                    </Collapse>
                  </div>
                  <div>
                    <div
                      onClick={() => setTopOutBound(!topOutBound)}
                      aria-expanded={topOutBound}
                      aria-label="show more"
                      style={{
                        cursor: 'pointer',
                        marginBottom: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                        padding: '8px',
                      }}
                    >
                      <IncomeCardHead>Top 5 Outflows</IncomeCardHead>

                      <ExpandMore
                        expand={topOutBound}
                        onClick={() => setTopOutBound(!topOutBound)}
                        aria-expanded={topOutBound}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </div>

                    <Collapse in={topOutBound} timeout="auto" unmountOnExit>
                      <MatrixTable
                        style={{
                          height: '450px',
                          margin: 0,
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th align="center">INBOUND/OUTBOUND</th>
                            <th align="center">Description</th>
                            <th align="center">Date</th>
                            <th align="center">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {topTransaction && topTransaction?.outFlow?.length ? (
                            <>
                              {topTransaction?.outFlow.map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>OUTBOUND</td>
                                  <td>{item?.description}</td>
                                  <td>
                                    {item?.date &&
                                      moment(item?.date).format('DD-MMM-YYYY')}
                                  </td>
                                  <td>
                                    ₦{' '}
                                    {(item?.amount || 0).toLocaleString(
                                      undefined,
                                      {
                                        maximumFractionDigits: 2,
                                      }
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </MatrixTable>
                    </Collapse>
                  </div>
                  <div>
                    <div
                      onClick={() => setBottomInBound(!bottomInBound)}
                      aria-expanded={bottomInBound}
                      aria-label="show more"
                      style={{
                        cursor: 'pointer',
                        marginBottom: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                        padding: '8px',
                      }}
                    >
                      <IncomeCardHead>Bottom 5 Inflows</IncomeCardHead>

                      <ExpandMore
                        expand={bottomInBound}
                        onClick={() => setBottomInBound(!bottomInBound)}
                        aria-expanded={bottomInBound}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </div>

                    <Collapse in={bottomInBound} timeout="auto" unmountOnExit>
                      <MatrixTable
                        style={{
                          height: '450px',
                          margin: 0,
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th align="center">INBOUND/OUTBOUND</th>
                            <th align="center">Description</th>
                            <th align="center">Date</th>
                            <th align="center">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {bottomTransaction &&
                          bottomTransaction?.inFlow?.length ? (
                            <>
                              {bottomTransaction?.inFlow.map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>INBOUND</td>
                                  <td>{item?.description}</td>
                                  <td>
                                    {item?.date &&
                                      moment(item?.date).format('DD-MMM-YYYY')}
                                  </td>
                                  <td>
                                    ₦{' '}
                                    {(item?.amount || 0).toLocaleString(
                                      undefined,
                                      {
                                        maximumFractionDigits: 2,
                                      }
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </MatrixTable>
                    </Collapse>
                  </div>
                  <div>
                    <div
                      onClick={() => setBottomOutBound(!bottomOutBound)}
                      aria-expanded={bottomOutBound}
                      aria-label="show more"
                      style={{
                        cursor: 'pointer',
                        marginBottom: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                        padding: '8px',
                      }}
                    >
                      <IncomeCardHead>Bottom 5 Outflows</IncomeCardHead>

                      <ExpandMore
                        expand={bottomOutBound}
                        onClick={() => setBottomOutBound(!bottomOutBound)}
                        aria-expanded={bottomOutBound}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </div>

                    <Collapse in={bottomOutBound} timeout="auto" unmountOnExit>
                      <MatrixTable
                        style={{
                          height: '450px',
                          margin: 0,
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th align="center">INBOUND/OUTBOUND</th>
                            <th align="center">Description</th>
                            <th align="center">Date</th>
                            <th align="center">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {bottomTransaction &&
                          bottomTransaction?.outFlow?.length ? (
                            <>
                              {bottomTransaction?.outFlow.map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>OUTBOUND</td>
                                  <td>{item?.description}</td>
                                  <td>
                                    {item?.date &&
                                      moment(item?.date).format('DD-MMM-YYYY')}
                                  </td>
                                  <td>
                                    ₦{' '}
                                    {(item?.amount || 0).toLocaleString(
                                      undefined,
                                      {
                                        maximumFractionDigits: 2,
                                      }
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </MatrixTable>
                    </Collapse>
                  </div>
                  <div>
                    <div
                      onClick={() =>
                        setTop5InBoundPaymentSME(!top5InBoundPaymentSME)
                      }
                      aria-expanded={top5InBoundPaymentSME}
                      aria-label="show more"
                      style={{
                        cursor: 'pointer',
                        marginBottom: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                        padding: '8px',
                      }}
                    >
                      <IncomeCardHead>
                        Top 5 Inbound Small Business Payments
                      </IncomeCardHead>

                      <ExpandMore
                        expand={top5InBoundPaymentSME}
                        onClick={() =>
                          setTop5InBoundPaymentSME(!top5InBoundPaymentSME)
                        }
                        aria-expanded={top5InBoundPaymentSME}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </div>
                    <Collapse
                      in={top5InBoundPaymentSME}
                      timeout="auto"
                      unmountOnExit
                    >
                      <MatrixTable
                        style={{
                          height: '450px',
                          margin: 0,
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th align="center">INBOUND/OUTBOUND</th>
                            <th align="center">Description</th>
                            <th align="center">Date</th>
                            <th align="center">SME</th>
                          </tr>
                        </thead>
                        <tbody>
                          {topPaymentSME && topPaymentSME?.inFlow?.length ? (
                            <>
                              {topPaymentSME?.inFlow.map((item, index) => (
                                <>
                                  {index < 5 && (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>INBOUND</td>
                                      <td>{item?.description}</td>
                                      <td>
                                        {item?.date &&
                                          moment(item?.date).format(
                                            'DD-MMM-YYYY'
                                          )}
                                      </td>
                                      <td>
                                        ₦{' '}
                                        {(item?.amount || 0).toLocaleString(
                                          undefined,
                                          {
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                  )}
                                </>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </MatrixTable>
                    </Collapse>
                    <div
                      onClick={() =>
                        setTop5OutBoundPaymentSME(!top5OutBoundPaymentSME)
                      }
                      aria-expanded={top5OutBoundPaymentSME}
                      aria-label="show more"
                      style={{
                        cursor: 'pointer',
                        marginBottom: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                        padding: '8px',
                      }}
                    >
                      <IncomeCardHead>
                        Top 5 Outbound Small Business Payments
                      </IncomeCardHead>

                      <ExpandMore
                        expand={top5OutBoundPaymentSME}
                        onClick={() =>
                          setTop5OutBoundPaymentSME(!top5OutBoundPaymentSME)
                        }
                        aria-expanded={top5OutBoundPaymentSME}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </div>

                    <Collapse
                      in={top5OutBoundPaymentSME}
                      timeout="auto"
                      unmountOnExit
                    >
                      <MatrixTable
                        style={{
                          height: '450px',
                          margin: 0,
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th align="center">INBOUND/OUTBOUND</th>
                            <th align="center">Description</th>
                            <th align="center">Date</th>
                            <th align="center">SME</th>
                          </tr>
                        </thead>
                        <tbody>
                          {topPaymentSME && topPaymentSME?.outFlow?.length ? (
                            <>
                              {topPaymentSME?.outFlow.map((item, index) => (
                                <>
                                  {index < 5 && (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>INBOUND</td>
                                      <td>{item?.description}</td>
                                      <td>
                                        {item?.date &&
                                          moment(item?.date).format(
                                            'DD-MMM-YYYY'
                                          )}
                                      </td>
                                      <td>
                                        ₦{' '}
                                        {(item?.amount || 0).toLocaleString(
                                          undefined,
                                          {
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                  )}
                                </>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </MatrixTable>
                    </Collapse>
                  </div>
                  <div>
                    <div
                      onClick={() =>
                        setTop5InBoundPaymentLME(!top5InBoundPaymentLME)
                      }
                      aria-expanded={top5InBoundPaymentLME}
                      aria-label="show more"
                      style={{
                        cursor: 'pointer',
                        marginBottom: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                        padding: '8px',
                      }}
                    >
                      <IncomeCardHead>
                        Top 5 Inbound Large Payments
                      </IncomeCardHead>

                      <ExpandMore
                        expand={top5InBoundPaymentLME}
                        onClick={() =>
                          setTop5InBoundPaymentLME(!top5InBoundPaymentLME)
                        }
                        aria-expanded={top5InBoundPaymentLME}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </div>

                    <Collapse
                      in={top5InBoundPaymentLME}
                      timeout="auto"
                      unmountOnExit
                    >
                      <MatrixTable
                        style={{
                          height: '450px',
                          margin: 0,
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th align="center">INBOUND/OUTBOUND</th>
                            <th align="center">Description</th>
                            <th align="center">Date</th>
                            <th align="center">LME</th>
                          </tr>
                        </thead>
                        <tbody>
                          {topPaymentLME && topPaymentLME?.inFlow?.length ? (
                            <>
                              {topPaymentLME?.inFlow.map((item, index) => (
                                <>
                                  {index < 5 && (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>OUTBOUND</td>
                                      <td>{item?.description}</td>
                                      <td>
                                        {item?.date &&
                                          moment(item?.date).format(
                                            'DD-MMM-YYYY'
                                          )}
                                      </td>
                                      <td>
                                        ₦{' '}
                                        {(item?.amount || 0).toLocaleString(
                                          undefined,
                                          {
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                  )}
                                </>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </MatrixTable>
                    </Collapse>
                  </div>
                  <div>
                    <div
                      onClick={() =>
                        setTop5OutBoundPaymentLME(!top5OutBoundPaymentLME)
                      }
                      aria-expanded={top5OutBoundPaymentLME}
                      aria-label="show more"
                      style={{
                        cursor: 'pointer',
                        marginBottom: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                        padding: '8px',
                      }}
                    >
                      <IncomeCardHead>
                        Top 5 Outbound Large Business Payments
                      </IncomeCardHead>

                      <ExpandMore
                        expand={top5OutBoundPaymentLME}
                        onClick={() =>
                          setTop5OutBoundPaymentLME(!top5OutBoundPaymentLME)
                        }
                        aria-expanded={top5OutBoundPaymentLME}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </div>

                    <Collapse
                      in={top5OutBoundPaymentLME}
                      timeout="auto"
                      unmountOnExit
                    >
                      <MatrixTable
                        style={{
                          height: '450px',
                          margin: 0,
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th align="center">INBOUND/OUTBOUND</th>
                            <th align="center">Description</th>
                            <th align="center">Date</th>
                            <th align="center">LME</th>
                          </tr>
                        </thead>
                        <tbody>
                          {topPaymentLME && topPaymentLME?.outFlow?.length ? (
                            <>
                              {topPaymentLME?.outFlow.map((item, index) => (
                                <>
                                  {index < 5 && (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>OUTBOUND</td>
                                      <td>{item?.description}</td>
                                      <td>
                                        {item?.date &&
                                          moment(item?.date).format(
                                            'DD-MMM-YYYY'
                                          )}
                                      </td>
                                      <td>
                                        ₦{' '}
                                        {(item?.amount || 0).toLocaleString(
                                          undefined,
                                          {
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                  )}
                                </>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </MatrixTable>
                    </Collapse>
                  </div>
                </SmallBoxCard>
              </IncomePageWrapperTop>

              <BoxCardTable>
                <IncomeTable>
                  <thead>
                    <tr>
                      <th>Source of income</th>
                      <th>Date</th>
                      <th>Description</th>
                      <th>Category</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterTransactionInfo?.length ? (
                      <>
                        {filterTransactionInfo.map((item, index) => (
                          <tr key={index}>
                            <td>{item?.detail}</td>
                            <td>
                              {item?.description !== 'Total' &&
                                moment(item?.date).format('DD MMM YYYY')}
                            </td>
                            <td>{item?.description}</td>
                            <td>{item?.category}</td>
                            <td>
                              ₦{' '}
                              {(item?.amount || 0).toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                              })}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </IncomeTable>
              </BoxCardTable>
            </IncomePageWrapperMain>
          </BoxWrapper>
        )}
      </MainStyle>
    </RootStyle>
  );
};
function calculateDateRang(allDateRange, setSelectedDateRang) {
  var result = [];
  allDateRange.forEach((v) => {
    var startDate = moment(v.startDate);
    var endDate = moment(v.endDate);
    if (endDate.isBefore(startDate)) {
      return;
    }
    while (startDate.isBefore(endDate)) {
      let lastDate = moment(startDate).endOf('month').format('YYYY-MM-DD');

      let diff = moment(endDate).diff(moment(lastDate), 'days');
      if (diff < 0) {
        lastDate = moment(endDate).format('YYYY-MM-DD');
      }

      result.push(lastDate);
      startDate.add(1, 'month');
    }
    result
      .sort(
        (a, b) =>
          new moment(a).format('YYYYMMDD') - new moment(b).format('YYYYMMDD')
      )
      .reverse();
    function filterUniqueDates(data) {
      const lookup = new Set();
      return data.filter((date) => {
        const serialised = new Date(date).getTime();

        if (lookup.has(serialised)) {
          return false;
        } else {
          lookup.add(serialised);
          return true;
        }
      });
    }
    let uniqueDates = filterUniqueDates(result);
    uniqueDates =
      uniqueDates.length &&
      uniqueDates.map((i) => {
        return {
          title: i,
          value: i,
        };
      });

    setSelectedDateRang(uniqueDates);
  });
}

export default IncomeAndJobVerification;
