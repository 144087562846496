// ----------------------------------------------------------------------

const GREY = {
  lighter: "#DEDEDE",
  light: "#A3A3A3",
  main: "#A5A6B6",
  dark: "#6E6F87",
  darker: "#FCFCFC",
  f0: "#F0F0F0",
  f1: "#F1F1F1",
};

const DARK = {
  light: "#767E8E",
  main: "#1B1D21",
  dark: "#191B1F",
  darker: "#17191C",
};

const PRIMARY = {
  main: "#fff",
  dark: "#a5a6b6",
  chart: "#004466",
};

const SUCCESS = {
  lighter: "#DAF1E3",
  light: "#91D4AA",
  main: "#40EA8F",
  dark: "#2B6E44",
  darker: "#0F2517",
};

const DANGER = {
  lighter: "#F9CEC6",
  light: "#F28D7C",
  main: "#ED3E3E",
  dark: "#EB4B33",
  darker: "#CC2D14",
};

const ORANGE = {
  main: "#FC5719",
};

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  danger: { ...DANGER },
  success: { ...SUCCESS },
  dark: { ...DARK },
  orange: { ...ORANGE },
  grey: { ...GREY },
  text: {
    orange: '#FC5719',
    primary: '#001823',
    dark: '#212120',
    light: 'rgba(33, 33, 32, 0.6);',
  },
};

export default palette;
