import {
  Button,
  ButtonGroup,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import {
  MainCardStyled,
  PageWrapper,
  StyledTableCellData,
  StyledTableCellDataItem,
  TableCellHead,
  TableWrapper,
} from "./CategoryInsightsStyle";
import DashboardUINav from "../../components/Navbar/DashboardUINav/DashboardUINav";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import { getUserData } from "../../components/Common/common";
// import GetAxiosInstance from "../../components/common/api";
import Loader from "../../components/Common/Loader";
import moment from "moment";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { toast } from "react-toastify";
import { MainStyle, RootStyle } from "../../components/Common/Style";
import {
  getCategories,
  getConsent,
  getDashboard,
} from '../../helpers/backend_helper';
const monthRange = [
  {
    title: "3 Months",
    value: 3,
  },
  {
    title: "6 Months",
    value: 6,
  },
  {
    title: "9 Months",
    value: 9,
  },
  {
    title: "12 Months",
    value: 12,
  },
];
function createData(tran_type, category_lvl1, category_lvl2) {
  return { tran_type, category_lvl1, category_lvl2 };
}

const rows = [
  createData("January", "50%", "₦ 500,000.00"),
  createData("February", "50%", "₦ 500,000.00"),
  createData("March", "50%", "₦ 500,000.00"),
  createData("April", "50%", "₦ 500,000.00"),
  createData("May", "50%", "₦ 500,000.00"),
  createData("June", "50%", "₦ 500,000.00"),
  createData("July", "50%", "₦ 500,000.00"),
  createData("August", "50%", "₦ 500,000.00"),
  createData("September", "50%", "₦ 500,000.00"),
  createData("October", "50%", "₦ 500,000.00"),
  createData("November", "50%", "₦ 500,000.00"),
  createData("December", "50%", "₦ 500,000.00"),
];

const ExpandList = ({ updateQuantity }) => {
  let { state } = useLocation();
  let history = useHistory();

  let location = state?.data?.location;

  let selectedMonth = state?.data?.selectMonth;

  let selectAccount = state?.data?.selectAccount;

  let selectedType= state?.data?.selectedType;

  const [transactionGroup, setTransactionGroup] = useState([]);
  const [loader, setLoader] = useState(false);
  let [nameSearch, setNameSearch] = useState("");
  let [filterValue, setFilterValue] = useState([]);
  let [dashboardData, setDashboardData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [offSet, setOffSet] = useState();
  const [consent, setConsent] = useState();
  const [endDate, setEndDate] = useState();
  useEffect(() => {
    let responseValue = [];

    if (nameSearch) {
      responseValue = transactionGroup.filter((item) => {
        return (
          (item.category_lvl1 &&
            item?.tran_amount_monthly_average !== null &&
            item?.category_lvl1
              .toLowerCase()
              .includes(nameSearch.toLocaleLowerCase())) ||
          (item.category_lvl2 &&
            item?.tran_amount_monthly_average !== null &&
            item?.category_lvl2
              .toLowerCase()
              .includes(nameSearch.toLocaleLowerCase()))
        );
      });
    } else {
      responseValue = transactionGroup.filter(
        (item) => item?.tran_amount_monthly_average !== null
      );
    }
    setOffSet(0 * 10);
    setFilterValue(responseValue);
  }, [nameSearch, transactionGroup]);
  // const offSet = currentPage * 10;
  useEffect(() => {
    setOffSet(currentPage * 10);
  }, [currentPage]);
  useEffect(async () => {
    let endDate = moment().format("YYYY-MM-DD");
    setLoader(true);
    await getConsent(location).then((res) => {
      if (res?.codes === 200) {
        // setLoader(false);
        // if (res.data?.data.length) {
        let accountNumberList = res?.data.map((v) => {
          return { title: v.accountNumber, value: v.accountNumber };
        });

        let selectedProduct = res?.data?.find(
          (item) => item.accountNumber === selectAccount
        );

        endDate = selectedProduct?.consent?.data?.dateTo
          ? moment(selectedProduct?.consent?.data?.dateTo).format("YYYY-MM-DD")
          : "";

        setEndDate(endDate);
        setConsent(res?.data);
        // }
        setConsent(res?.data);
      } else if (res?.codes === 404) {
        history.push("/login");
      } else {
        setLoader(false);
        toast.error(
          res?.response?.data?.message || res.message || "Something went wrong!"
        );
      }
    });
    let dataArray = [];
    let userData = getUserData();
    let data = state?.data?.selectedDateRang.length;
    monthRange.forEach((v, index) => {
      if (data >= v.value) {
        dataArray.push(v);
      }
    });
    let dashboardPayload = {
      userId: location?.state?.client,
      date_to: state?.data?.selectedEndDate,
      n_months: selectedMonth,
      account_id: selectAccount,
    };
    await getDashboard(dashboardPayload).then((res) => {
      if (res?.codes === 200) {
        // setLoader(false);

        // if (res?.data?.data?.data?.length) {
        setDashboardData(res?.data?.monthly);
        // }
      } else {
        setDashboardData([]);
        setLoader(false);
        toast.error(res?.response?.data?.message || res.message);
      }
    });
    let payload = {
      user_id: location?.state?.client,
      date_to: state?.data?.selectedEndDate,

      n_months: selectedMonth,
      account_id: selectAccount,
      tran_threshold: 0,
    };

    await getCategories(payload).then((res) => {
      if (res?.codes === 200) {
        if (
          res?.data?.transaction_groups.length &&
          res?.data?.disposable_income &&
          res?.data?.monthly_transactions.length
        ) {
          setLoader(false);
          let result = res.data;
          let transactions = result?.transaction_groups.filter((item) => {
            return (
              item.category_lvl1 !== null &&
              item.category_lv2 !== null &&
              item.aggregation_lvl === 2
            );
          });

          setTransactionGroup(transactions);
        } else {
          setTransactionGroup([]);
        }
      } else {
        setLoader(false);
        toast.error(res?.response?.data?.message || res.message);
      }
    });
  }, []);
  const handleBack = () => {
    history.push("/category-insights", { ...location?.state });
  };
  const theme = useTheme();
  return (
    <RootStyle>
      <DashboardUINav
        location={state?.data?.location}
        dashboardData={dashboardData}
        consent={consent}
      />

      <MainStyle>
        <PageWrapper mt={5}>
          <MainCardStyled>
            <Stack
              my={3}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" alignItems="start" gap={1}>
                <Box onClick={handleBack}>
                  <ArrowBackIcon
                    sx={{
                      fill: (theme) => theme.palette.common.black,
                    }}
                  />
                </Box>
                <Typography variant="Body_medium_18">
                  Transaction Groups
                </Typography>
              </Stack>
              <TextField
                variant="filled"
                id="filled-hidden-label-normal"
                placeholder="Search for a bank..."
                autoComplete="off"
                hiddenLabel
                // fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setNameSearch(e.currentTarget.value);
                }}
              />
            </Stack>

            <TableContainer>
              <Table
                stickyHeader
                sx={{
                  minWidth: 350,
                }}
              >
                {loader ? (
                  <Loader />
                ) : (
                  <>
                    <TableHead>
                      <TableRow>
                        <TableCellHead>Type</TableCellHead>
                        <TableCellHead>Category</TableCellHead>
                        <TableCellHead>Subcategory</TableCellHead>
                        <TableCellHead>Amount</TableCellHead>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {filterValue
                        .slice(offSet, offSet + 10)
                        .map((row, index) => (
                          <TableRow hover key={index}>
                            <StyledTableCellDataItem>
                              {row.tran_type}
                            </StyledTableCellDataItem>
                            <StyledTableCellData>
                              {row?.category_lvl1}
                            </StyledTableCellData>
                            <StyledTableCellData>
                              {row?.category_lvl2}
                            </StyledTableCellData>
                            <StyledTableCellData>
                                  ₦{' '}
                                  {(row?.[selectedType] || 0).toLocaleString(
                                    undefined,
                                    {
                                      maximumFractionDigits: 2,
                                    }
                                  )}{' '}
                                </StyledTableCellData>
                          </TableRow>
                        ))}
                    </TableBody>
                  </>
                )}
              </Table>
            </TableContainer>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 3.5,
              }}
            >
              <Box width={"130px"} />
              <Box width={"110px"}>
                <Button
                  fullWidth
                  size="large"
                  sx={{
                    background: theme.palette.grey.f0,
                    color: theme.palette.common.black,
                    px: 5,
                    "&:hover": {
                      backgroundColor: theme.palette.grey.f0,
                    },
                  }}
                >
                  {" "}
                  Next{" "}
                </Button>
              </Box>
              <Stack direction="row" alignItems="center" gap={1}>
                <Increment
                  quantity={1}
                  updateQuantity={updateQuantity}
                  setCurrentPage={setCurrentPage}
                  pageCount={parseInt(filterValue.length / 10)}
                />{" "}
                of {parseInt(filterValue.length / 10)}
              </Stack>
            </Box>
          </MainCardStyled>
        </PageWrapper>
      </MainStyle>
    </RootStyle>
  );
};

const Increment = ({
  itemId,
  quantity,
  updateQuantity,
  setCurrentPage,
  pageCount,
}) => {
  const theme = useTheme();
  const [value, setValue] = useState(quantity);

  const incrementHandler = () => {
    setValue(value - 1);
    // updateQuantity(itemId, value - 1);
  };

  const decrementHandler = () => {
    if (pageCount > value) {
      setValue(value + 1);
    }
    // updateQuantity(itemId, value + 1);
  };
  useEffect(() => {
    setCurrentPage(value);
  }, [value]);

  return (
    <ButtonGroup
      size="large"
      variant="text"
      color="inherit"
      sx={{
        border: "none",
        backgroundColor: "transparent",
      }}
    >
      <Button
        key="three"
        disabled={value <= 1}
        onClick={incrementHandler}
        sx={{
          pr: 1,
          pl: 1,
          minWidth: "0px !important",
          backgroundColor: theme.palette.grey.f1,
          borderRight: "none !important",

          "&:hover": {
            backgroundColor: theme.palette.grey.f0,
          },
        }}
      >
        <ChevronLeftIcon
          fontSize="medium"
          sx={{ fill: theme.palette.common.black }}
        />
      </Button>
      <Button
        key="two"
        sx={{
          pl: 3,
          pr: 3,
          backgroundColor: theme.palette.grey.f1,
          borderRight: "none !important",
          color: theme.palette.common.black,

          "&:hover": {
            backgroundColor: theme.palette.grey.f0,
          },
        }}
      >
        {value}
      </Button>
      <Button
        key="one"
        onClick={decrementHandler}
        sx={{
          pl: 1,
          pr: 1,
          minWidth: "0px !important",
          backgroundColor: theme.palette.grey.f1,
          borderRight: "none !important",

          "&:hover": {
            backgroundColor: theme.palette.grey.f0,
          },
        }}
      >
        <ChevronRightIcon
          fontSize="medium"
          sx={{ fill: theme.palette.common.black }}
        />
      </Button>
    </ButtonGroup>
  );
};

Increment.propTypes = {
  itemId: PropTypes.number,
  quantity: PropTypes.number,
  updateQuantity: PropTypes.func,
};

export default ExpandList;
