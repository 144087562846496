import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Alert, Card, CardBody, Col, Container, Label, Row } from 'reactstrap';

// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

// availity-reactstrap-validation
import { AvField, AvForm } from 'availity-reactstrap-validation';

// action
import { userResetPassword } from "../../store/actions";

// import images
import logoActive from "../../assets/images/logo-active.png";
import { postresetPwd } from '../../helpers/backend_helper';

const ResetPassword = (props) => {
  const [tokenType] = useState('primary');
  const [error, setError] = useState();
  const handleValidSubmit = (event, values) => {
    let user = {
      otp: values.otp,
      newpassword: values.password,
      confirmpassword: values.cnfPassword,
    };
    if (tokenType === 'secondary') {
      user['alias'] = values.alias;
    }
    postresetPwd(user)
      .then((response) => {
        if (response.codes === 200) {
          response.data['tokenType'] =
            response.data.tokenType !== undefined
              ? response.data.tokenType
              : 'RESET_PASSWORD';
          localStorage.setItem('authUser', JSON.stringify(response));
          props.history.push('/login');
        } else {
          setError(response.message);
        }
      })
      .catch((error) => {});
  };
  useEffect(() => {
    document.body.className = 'authentication-bg';
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = '';
    };
  });
  useEffect(() => {
    if (props.forgetError || props.forgetSuccessMsg) {
    }
  }, [props.forgetError, props.forgetSuccessMsg]);

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="mdi mdi-home-variant h2"></i>
        </Link>
      </div>

      <div className="account-pages my-5  pt-sm-5">
        <Container>
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div>
                <a href="/" className="mb-5 d-block auth-logo">
                  <img
                    src={logoActive}
                    alt=""
                    height="50"
                    className="logo logo-dark"
                  />
                </a>
                <Card>
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Reset Password</h5>
                      <p className="text-muted">
                        Create new password to proceed.
                      </p>
                    </div>
                    <div className="p-2 mt-4">
                      {props.forgetError && props.forgetError ? (
                        <Alert
                          color="danger"
                          className="text-center mb-4"
                          style={{ marginTop: '13px' }}
                        >
                          {props.forgetError}
                        </Alert>
                      ) : null}
                      {props.forgetSuccessMsg ? (
                        <Alert
                          color="success"
                          className="text-center mb-4"
                          style={{ marginTop: '13px' }}
                        >
                          {props.forgetSuccessMsg}
                        </Alert>
                      ) : null}

                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                      >
                        {error && typeof error === 'string' ? (
                          <Alert color="danger">{error}</Alert>
                        ) : null}

                        <div className="col-20 mb-3">
                          <Label htmlFor="resetcode">Otp </Label>
                          <div>
                            <AvField
                              name="otp"
                              type="password"
                              className="form-control"
                              id="Otp code"
                              placeholder="Otp "
                              errorMessage="Enter OTP"
                              validate={{
                                required: { value: true },
                                maxLength: { value: 6 },
                              }}
                            />
                          </div>
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="password">Password</Label>
                          <div>
                            <AvField
                              name="password"
                              type="password"
                              className="form-control"
                              id="password"
                              placeholder="Password"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: 'Please enter a password',
                                },
                                minLength: {
                                  value: 6,
                                  errorMessage:
                                    'Your password must be between 6 and 9 letters',
                                },
                                maxLength: {
                                  value: 9,
                                  errorMessage:
                                    'Your password must be between 6 and 9 letters',
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="confirmpassword">
                            Confirm Password
                          </Label>
                          <div>
                            <AvField
                              name="cnfPassword"
                              type="password"
                              className="form-control"
                              id="confirmpassword"
                              placeholder="Confirm Password"
                              errorMessage="Password and confirm passowrd must be same"
                              validate={{
                                required: { value: true },
                                match: { value: 'password' },
                              }}
                            />
                          </div>
                        </div>

                        <Row className="row mb-0">
                          <Col className="col-12 text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Reset My Password
                            </button>
                          </Col>
                        </Row>
                        <div className="mt-4 text-center">
                          <p className="mb-0">
                            Remember It ?{' '}
                            <Link
                              to="/login"
                              className="fw-medium text-primary"
                            >
                              {' '}
                              Signin{' '}
                            </Link>
                          </p>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                {/* <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} Minible. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p>
                </div> */}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

ResetPassword.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default withRouter(
  connect(mapStatetoProps, { userResetPassword })(ResetPassword)
);
