import {
  Box,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MainCard from "../../components/Common/MainCard";

export const PageWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2, 5, 2),
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(0, 10, 5, 10),
  },
}));

export const MainCardStyled = styled(MainCard)(({ theme }) => ({
  backgroundColor: "transparent",
  boxShadow: "none",
  height: "100%",
  [theme.breakpoints.up("sm")]: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const CircularProgressWrapperMain = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: "100%",
  padding: '24px 0',
  flexWrap: 'wrap',
  // justify-content: space-between,
  gap: '20px'
}));

export const CircularProgressWrapperIaJ = styled(Box)(({ theme }) => ({
  width: '100%',
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  gap: 3,
  [theme.breakpoints.up("sm")]: {
    width: '30%'
  },
}));

export const ListWrapper = styled(Box)(({ theme }) => ({
  margin: '16px 0',
}));


export const TableWrapper = styled(TableContainer)(({ theme }) => ({
  maxHeight: "auto",
  overflowX: "hidden",
  [theme.breakpoints.up("sm")]: {
    maxHeight: "380px",
  },
}));

export const TableCellHead = styled(TableCell)(({ theme }) => ({
  // border: "none",
  backgroundColor: 'white',
  borderBottom: "3px solid transparent",
  color: theme.palette.common.black,
  ...theme.typography.Body_medium_14,
  [theme.breakpoints.up("sm")]: {
    ...theme.typography.Body_medium_16,
  },
}));

export const StyledTableCellData = styled(TableCell)(({ theme }) => ({
  border: "none",
  // borderBottom: `1px solid ${theme.palette.grey.lighter}`,
  ...theme.typography.Body_light_14,
  [theme.breakpoints.up("sm")]: {
    ...theme.typography.Body_light_16,
  },
}));
