import { Box, Button, Divider, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

//
export const OutlineButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  border: `1px solid ${theme.palette.orange.main}`,
  width: "auto",
  // width: "-webkit-fill-available !important",
  textAlign: "center",
  color: theme.palette.orange.main,

  [theme.breakpoints.up("md")]: {
    width: "215px",
  },
}));

export const FillButton = styled(Button)(({ theme }) => ({
  width: "auto",
  // width: "-webkit-fill-available !important",
  textAlign: "center",

  [theme.breakpoints.up("md")]: {
    width: "215px",
  },
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.grey.f0,
  margin: theme.spacing(3, 0),
}));

export const StyledDivider2 = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.grey.f0,
  margin: theme.spacing(1.5, 0),
  borderWidth: 1,
}));

export const CustomPaper = (props) => {
  return (
    <Paper
      elevation={8}
      sx={{
        backgroundColor: (theme) => theme.palette.common.white,
      }}
      {...props}
    />
  );
};

export const BoxWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2, 0, 2),
  margin: theme.spacing(5, 0),

  [theme.breakpoints.up("lg")]: {
    padding: theme.spacing(0, 5, 0, 5),
  },
}));

export const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

export const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100vh",
  backgroundColor: "#f1f1f1",
  paddingTop: 80,
}));
