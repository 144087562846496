import PropTypes from "prop-types";
import moment from 'moment';
// material-ui
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Card,
  Chip,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import MainCard from "../../../components/Common/MainCard";

import { ReactComponent as CheckIcon } from "../../../assets/images/checkIcon.svg";
import { ReactComponent as FailedIcon } from "../../../assets/images/failed-payment.svg";
import { ReactComponent as UpcomingIcon } from "../../../assets/images/upcoming-payment.svg";

// assets
import DeleteIcon from "@mui/icons-material/Delete";
// import getAxiosInstance from 'api';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getPayment, getPaymentSummary } from '../../../helpers/backend_helper';

// table header
const headCells = [
  {
    id: 'customerName',
    numeric: false,
    disablePadding: false,
    label: 'Customer Name',
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: false,
    label: 'Amount',
  },
  {
    id: 'paymentDate',
    numeric: true,
    disablePadding: false,
    label: 'Payment Date',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'createdDate',
    numeric: true,
    disablePadding: false,
    label: 'Created Date',
  },
  {
    id: '',
    numeric: true,
    disablePadding: false,
    width: '0.5px',
    label: '',
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  border: '1px solid #DEDEDE',
  color: '#212120',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '500',
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  height: 'auto',
  padding: '10px 20px',
  fontSize: '20px',
  borderRadius: '16px',
  '&.failed': {
    backgroundColor: 'rgba(255, 0, 0, 0.1)',
    color: 'red',
  },
  '&.success': {
    backgroundColor: 'rgba(0, 255, 0, 0.1)',
    color: 'green',
  },
}));

// ==============================|| TABLE - HEADER ||============================== //

function EnhancedTableHead({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
    backgroundColor: '#F0F0F0',
    color: 'rgba(33, 33, 32, 0.60)',
    border: '1px solid #DEDEDE',
  }));

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCellHead
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : undefined}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCellHead>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// ==============================|| TABLE - TOOLBAR ||============================== //

const EnhancedTableToolbar = ({ numSelected }) => (
  <Toolbar
    sx={{
      p: 0,
      pl: 1,
      pr: 1,
      ...(numSelected > 0 && {
        color: (theme) => theme.palette.secondary.main,
      }),
    }}
  >
    {numSelected > 0 ? (
      <Typography color="inherit" variant="subtitle1">
        {numSelected} selected
      </Typography>
    ) : (
      <Typography variant="h4" id="tableTitle">
        Nutrition
      </Typography>
    )}
    <Box sx={{ flexGrow: 1 }} />
    {numSelected > 0 && (
      <Tooltip title="Delete">
        <IconButton size="large">
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    )}
  </Toolbar>
);

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

// ==============================|| TABLE - ENHANCED ||============================== //

export default function Payments({ appName }) {
  const theme = useTheme();
  const userData = JSON.parse(localStorage.getItem('userData'));
  const [order, setOrder] = useState('DESC');
  const [orderBy, setOrderBy] = useState('id');
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [dense] = useState(false);
  const [limit, setLimit] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [apiList, setApiList] = useState([]);
  const [total, setTotal] = useState();
  const [isFilter, setIsFilter] = useState(true);
  const [paymentList, setPaymentList] = useState([]);
  const [paymentTotal, setPaymentTotal] = useState({});
  const [summary, setSummary] = useState({});
  const fetchPaymentList = async () => {
    const payload = {
      page: page + 1,
      limit: limit,
      sortBy: orderBy,
      sortDirection: order,
      search: '',
    };
    await getPayment(payload)
      .then((res) => {
        setPaymentList(res?.data.items || []);
        setPaymentTotal(res?.data.total);
      })
      .catch((err) => {
        toast.error('Something went wrong!');
      });
  };
  const fetchPaymentSummary = async () => {
    await getPaymentSummary()
      .then((res) => {
        setSummary(res.data);
      })
      .catch((err) => {
        toast.error('Something went wrong!');
      });
  };

  useEffect(() => {
    fetchPaymentList();
    fetchPaymentSummary();
  }, [limit, page]);

  const handleMenuClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'ASC';
    setOrder(isAsc ? 'DESC' : 'ASC');
    setOrderBy(property);
  };
  const isSelected = () => {};

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(event.target.value);
  };

  return (
    <Box mt={1}>
      <Grid container spacing={5}>
        <Grid item xs={12} md={4}>
          <MainCard>
            <Stack
              direction="row"
              justifyContent="space-between"
              gap={1}
              alignItems={'center'}
            >
              <UpcomingIcon />
              <Stack direction="column" gap={2}>
                <Typography variant="Body_medium_18">
                  Total Upcoming Payments{' '}
                </Typography>
                <Typography variant="Body_semibold_28">
                  ₦{summary?.totalUpcomingAmount}
                </Typography>
                <Box />
              </Stack>
            </Stack>
          </MainCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <MainCard>
            <Stack
              direction="row"
              justifyContent="space-between"
              gap={1}
              alignItems={'center'}
            >
              <FailedIcon />
              <Stack direction="column" gap={2}>
                <Typography variant="Body_medium_18">
                  Total Failed Payments{' '}
                </Typography>
                <Typography variant="Body_semibold_28">
                  ₦{summary?.totalFailedAmount}
                </Typography>
                <Box />
              </Stack>
            </Stack>
          </MainCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <MainCard>
            <Stack
              direction="row"
              justifyContent="space-between"
              gap={1}
              alignItems={'center'}
            >
              <CheckIcon />
              <Stack direction="column" gap={2}>
                <Typography variant="Body_medium_18">
                  Total Collected Payments
                </Typography>
                <Typography variant="Body_semibold_28">
                  {' '}
                  ₦{summary?.totalCollectionAmount}
                </Typography>
                <Box />
              </Stack>
            </Stack>
          </MainCard>
        </Grid>
      </Grid>

      <Card sx={{ mt: 2 }}>
        <>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          {/* table */}
          <TableContainer>
            <Table
              sx={{
                minWidth: 750,
                backgroundColor: 'white',
              }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                // onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={total}
              />
              {console.log('paymentList', paymentList)}
              <TableBody>
                {paymentList.length ? (
                  <>
                    {paymentList.map((row, index) => {
                      if (typeof row === 'number') return null;
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          <StyledTableCell id={labelId}>
                            {row?.userName}
                          </StyledTableCell>
                          <StyledTableCell>{row?.amount}</StyledTableCell>
                          <StyledTableCell>
                            {moment(row?.scheduleDate).format(
                              'MMM DD ,YYYY hh:mm A'
                            )}
                          </StyledTableCell>
                          {/* <StyledChip label="Failed" className="failed" />
                            <StyledChip label="Success" className="success" /> */}
                          <StyledTableCell>
                            {row?.paymentStatus}{' '}
                          </StyledTableCell>

                          <StyledTableCell>
                            {moment(row?.createdAt).format(
                              'MMM DD ,YYYY hh:mm A'
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            <MoreVertIcon />
                          </StyledTableCell>
                        </TableRow>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* table pagination */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={paymentTotal}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      </Card>
    </Box>
  );
}
