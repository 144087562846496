import React, { useState } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  Tabs,
  Tab,
  TextField,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Container } from "reactstrap";
import { styled, useTheme } from "@mui/material/styles";
import Templates from "./Tabs/Templates";
import Payments from "./Tabs/Payments";
import Customers from "./Tabs/Customers";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";
import MainCard from "../../components/Common/MainCard";

import { ReactComponent as UserIcon } from "../../assets/images/user.svg";
import AddTemplateModal from './Tabs/AddTemplateModal';

const LinkBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#fc57190d',
  padding: '0px 16px',
  borderRadius: theme.spacing(1.5),
  width: '50%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 1 }}>{children}</Box>}
    </div>
  );
};

const DirectDebit = () => {
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleNewTemplate = () => {
    setIsOpen(!isOpen);
  };
  const tabs = [
    { label: 'Mandate', content: <Templates isOpen={isOpen} /> },
    { label: 'Payments', content: <Payments /> },
    { label: 'Customers', content: <Customers /> },
  ];

  return (
    <div className="page-content">
      <Container fluid>
        <Stack
          direction={'row'}
          flexWrap={'wrap'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{ mb: 3 }}
        >
          <Typography variant="Body_light_20" fontWeight={600}>
            Direct Debit
          </Typography>
          <Button
            color="secondary"
            sx={{ width: 'fit-content' }}
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={handleNewTemplate}
          >
            New Mandate
          </Button>
        </Stack>
        <Box>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="direct debit tabs"
            textColor="inherit"
            TabIndicatorProps={{
              style: { backgroundColor: theme.palette.orange.main },
            }}
          >
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                sx={{
                  textTransform: 'capitalize',
                  color: theme.palette.text.dark,
                  '&.Mui-selected': { color: theme.palette.orange.main },
                }}
              />
            ))}
          </Tabs>
          {tabs.map((tab, index) => (
            <TabPanel key={index} value={tabValue} index={index}>
              {tab.content}
            </TabPanel>
          ))}
        </Box>
      </Container>
      <AddTemplateModal isOpen={isOpen} handleNewTemplate={handleNewTemplate} />
    </div>
  );
};

export default DirectDebit;
