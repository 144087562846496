import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import { useTheme } from "@emotion/react";
import MainCard from "./Common/MainCard";
import { TableCellHead } from "../pages/dashboard-ui/DashboardUIStyle";
import { TableCellData } from "./Common/Table";
const MainCardStyled = styled(MainCard)(({ theme }) => ({
  backgroundColor: "transparent",
  boxShadow: "none",
  [theme.breakpoints.up("sm")]: {
    backgroundColor: theme.palette.primary.color1,
  },
}));
const StyledTableCellDataItem = styled(TableCell)(({ theme }) => ({
  // border: "none",
  borderBottom: `1px solid ${theme.palette.primary.bg}`,
  ...theme.typography.Body_medium_14,
  [theme.breakpoints.up("sm")]: {
    ...theme.typography.Body_medium_16,
  },
}));
export default function TransactionModal({ isOpen, setIsOpen, selectedValue }) {
  const theme = useTheme();

  return (
    <Dialog
      open={isOpen}
      onClose={''}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: '750px',
          bgcolor: theme.palette.primary.light,
          p: 4,
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" paddingBottom={3}>
        <Typography variant="Body_medium_20" color="orange.main">
          Transaction Information
        </Typography>
        <Box
          sx={{ display: 'flex', justifyContent: 'end', cursor: 'pointer' }}
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <CloseIcon />
        </Box>
      </Box>
      <MainCardStyled>
        <TableContainer>
          <Table
            stickyHeader
            sx={{
              minWidth: 400,
            }}
          >
            <TableHead>
              <TableRow>
                <TableCellHead align="center">Category</TableCellHead>
                <TableCellHead align="center">Date</TableCellHead>
                <TableCellHead align="center">Description</TableCellHead>
                <TableCellHead align="center">Detail</TableCellHead>
                <TableCellHead align="center">Amount</TableCellHead>
              </TableRow>
            </TableHead>

            <TableBody>
              {selectedValue?.data?.length ? (
                <>
                  {selectedValue?.data?.map((row, index) => (
                    <TableRow hover key={index} padding="4">
                      <>
                        <TableCellData align="center">
                          {row?.category}
                        </TableCellData>
                        <TableCellData align="center">
                          {row?.date}
                        </TableCellData>
                        <TableCellData align="center">
                          {row?.description}
                        </TableCellData>
                        <TableCellData align="center">
                          {row?.detail}
                        </TableCellData>
                        <TableCellData align="center">
                          {row?.amount}
                        </TableCellData>
                      </>
                    </TableRow>
                  ))}
                  <TableRow hover>
                    <StyledTableCellDataItem></StyledTableCellDataItem>
                    <StyledTableCellDataItem></StyledTableCellDataItem>
                    <StyledTableCellDataItem></StyledTableCellDataItem>
                    <StyledTableCellDataItem align="center">
                      Total
                    </StyledTableCellDataItem>
                    <StyledTableCellDataItem align="center">
                      {selectedValue?.tran_amount || 0}
                    </StyledTableCellDataItem>
                  </TableRow>
                </>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </MainCardStyled>
    </Dialog>
  );
}
