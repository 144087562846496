import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from 'reactstrap';

// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { postForgetPwd } from '../../helpers/backend_helper';

// availity-reactstrap-validation
import { AvField, AvForm } from 'availity-reactstrap-validation';

// action
import { userForgetPassword } from '../../store/actions';

// import images
import logoActive from '../../assets/images/logo-active.png';

const ForgetPasswordPage = (props) => {
  const [userType] = useState('primary');
  const [loading, setLoading] = useState(false);
  const [setError] = useState();
  const [setEmail] = useState();
  const handleValidSubmit = (event, values) => {
    let user = {
      otp: values.otp,
      email: values.email,
    };
    if (userType === 'secondary') {
      user['alias'] = values.alias;
    }
    setLoading(true);
    postForgetPwd(user)
      .then((response) => {
        if (response.codes === 200) {
          response.data['userType'] =
            response.data.userType !== undefined ? response.data.userType : 'P';
          localStorage.setItem('authUser', JSON.stringify(response));
          props.history.push('/reset-password');
          setLoading(false);
        } else {
          setError(response.message);
          setLoading(false);
        }
      })
      .catch((error) => {});
  };
  useEffect(() => {
    document.body.className = 'authentication-bg';
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = '';
    };
  });
  useEffect(() => {
    if (props.forgetError || props.forgetSuccessMsg) {
      setLoading(false);
    }
  }, [props.forgetError, props.forgetSuccessMsg]);

  return (
    <React.Fragment>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="mdi mdi-home-variant h2"></i>
        </Link>
      </div> */}

      <div className="account-pages my-5  pt-sm-5">
        <Container>
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div>
                <Card>
                  <CardBody className="p-4">
                    <a href="/" className="my-2 d-block auth-logo">
                      <img
                        src={logoActive}
                        alt=""
                        height="50"
                        className="logo logo-dark"
                      />
                    </a>
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Reset Password</h5>
                      {/* <p className="text-muted">Reset Password with Minible.</p> */}
                    </div>
                    <div className="p-2 mt-4">
                      <div
                        className="alert alert-success text-center mb-4"
                        role="alert"
                      >
                        Enter your Email and OTP will be sent to you!
                      </div>
                      {props.forgetError && props.forgetError ? (
                        <Alert
                          color="danger"
                          className="text-center mb-4"
                          style={{ marginTop: '13px' }}
                        >
                          {props.forgetError}
                        </Alert>
                      ) : null}
                      {props.forgetSuccessMsg ? (
                        <Alert
                          color="success"
                          className="text-center mb-4"
                          style={{ marginTop: '13px' }}
                        >
                          {props.forgetSuccessMsg}
                        </Alert>
                      ) : null}

                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                      >
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />
                        </div>
                        <Row className="row mb-0">
                          <Col className="col-12 text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                              disabled={loading}
                            >
                              {loading ? (
                                <span>
                                  <Spinner
                                    className="spinner-border-sm mr-5"
                                    color="white"
                                  />{' '}
                                  Loading...
                                </span>
                              ) : (
                                'Send OTP'
                              )}
                            </button>
                          </Col>
                        </Row>
                        {
                          <div className="mt-4 text-center">
                            <p className="mb-0">
                              Remember It ?{' '}
                              <Link
                                to="/login"
                                className="fw-medium text-primary"
                              >
                                {' '}
                                Signin{' '}
                              </Link>
                            </p>
                          </div>
                        }
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                {/* <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} Minible. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p>
                </div> */}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func
}

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword
  return { forgetError, forgetSuccessMsg }
}

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ForgetPasswordPage)
)
