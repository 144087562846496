import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import logoActive from '../../assets/images/logo-active.png';
import WemaBank from '../../assets/images/wema.svg';
import { Card, CardContent, IconButton, Stack, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '500px',
  width: '90%',
  borderRadius: '20px',
  bgcolor: 'background.paper',
  border: '2px solid transparent',
  boxShadow: 24,
  p: '28px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 3,
};

const UnitCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#F9F9F9',
  width: '100%',
}));

export default function AddUnitModal({ setIsOpen, isOpen }) {
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={style}>
          <Box
            component="img"
            src={logoActive}
            alt="Logo"
            width={94}
            height={41}
          />
          <IconButton
            sx={{ position: 'absolute', top: 10, right: 20 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>

          <Typography
            variant="Body_light_16"
            fontWeight={400}
            textAlign={'center'}
          >
            Pay into this account and send receipt of transaction to {''}
            <b>support@e-doconline.co.uk</b> to credit your wallet
          </Typography>

          <UnitCard>
            <CardContent>
              <Stack direction={'column'} gap={2}>
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Typography
                    varianr="Body_light_14"
                    fontWeight={400}
                    color={'text.light'}
                  >
                    Name
                  </Typography>
                  <Typography variant="Body_semibold_14" color={'text.dark'}>
                    E-doc online
                  </Typography>
                </Stack>
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Typography
                    varianr="Body_light_14"
                    fontWeight={400}
                    color={'text.light'}
                  >
                    Account No
                  </Typography>
                  <Typography variant="Body_semibold_14" color={'text.dark'}>
                    08234533
                  </Typography>
                </Stack>
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Typography
                    varianr="Body_light_14"
                    fontWeight={400}
                    color={'text.light'}
                  >
                    Bank
                  </Typography>
                  <Stack direction={'row'} alignItems={'center'} gap={1}>
                    <Box component={'img'} src={WemaBank} />
                    <Typography variant="Body_semibold_14" color={'text.dark'}>
                      Wema
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </CardContent>
          </UnitCard>
        </Box>
      </Modal>
    </div>
  );
}
