import React , {useEffect} from "react"
import { Link } from "react-router-dom"
import { CardBody, Container, Row, Col ,Card} from "reactstrap"

//Import Images
import logo from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import logoActive from "../../assets/images/logo-active.png"
import checkSuccess from "../../assets/images/check-success.png";
import maintenance from "../../assets/images/maintenance.png"
import dropbox from "../../assets/images/dropbox.svg"

const RegistrationSuccessful = () => {

  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  return (
    <React.Fragment>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark"><i className="mdi mdi-home-variant h2"></i></Link>
      </div> */}
      <div className="my-5 pt-sm-5">
        <Container>
          <Row>
            <div className="col-12 text-center">
              <div className="home-wrapper registration-box">
                <Link to="/" className="mb-5 d-block auth-logo">
                  {/* <img src={logo} alt="" height="22" className="logo logo-dark" />
                  <img src={logolight} alt="" height="22" className="logo logo-light" /> */}
                  <img src={logoActive} alt="" height="50" className="logo logo-dark" />
                </Link>
                <Row className="justify-content-center">
                  <Col lg={4} sm={5}>
                    <div className="maintenance-img">
                      <img src={dropbox} width="80" alt="" className="img-fluid mx-auto d-block" />
                    </div>
                  </Col>
                  <Col sm={12}>
                    <h4 className="my-3">Registration successful</h4>
                  </Col>
                </Row>
                <p className="w-50 m-auto">We've sent activation link to your email id.</p>
                <div className="mt-3 text-center">
                  
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RegistrationSuccessful
