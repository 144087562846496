import React from "react"
import ReactApexChart from "react-apexcharts"

const Dountchart = (props) => {
  const series = [parseFloat(props.detailData.TotalExpense.toFixed(2)), parseFloat(props.detailData.TotalIncome.toFixed(2))]
  const options = {
    labels: ["TotalExpense", "TotalIncome"],
    colors: ["#34c38f", "#5b73e8"],
    legend: {
      show: !0,
      position: 'bottom',
      horizontalAlign: 'center',
      verticalAlign: 'middle',
      floating: !1,
      fontSize: '14px',
      offsetX: 0
    },
    responsive: [{
      breakpoint: 600,
      options: {
        chart: {
          height: 240
        },
        legend: {
          show: !1
        },
      }
    }],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: false
            },
            value: {
              offsetY: -1,
              show: true,
              formatter: function (val) {
               
                return val.toFixed(2);
              }
            },
            // total: {
            //   show: true,
            //   label: 'Total',
            //   color: '#000000',
            //   formatter: function () {
                
            //       return ((props?.detailData?.TotalIncome - props?.detailData?.TotalExpense)/props.month).toFixed(2);
               
            //   }
            // }
            total: {
              show: true,
              label: 'Total',
              color: '#000000',
              formatter: function (val) {
                return ((parseFloat(val.globals.initialSeries[1]) - parseFloat(val.globals.initialSeries[0])) / props.month).toFixed(2);
              }
            }
          }
        }
      }
    }
  }
  return (
    <ReactApexChart
      options={options}
      series={series}
      type="donut"
      height="320"
      className="apex-charts"
    />
  )
}

export default Dountchart
