import React, { useEffect, useState } from 'react';

import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from 'reactstrap';

// Redux
import { Link, withRouter } from 'react-router-dom';

// availity-reactstrap-validation
import { AvField, AvForm } from 'availity-reactstrap-validation';

// actions
//import { loginUser, apiError, socialLogin } from "../../store/actions";

// api
import { postChangePasswordOtp } from '../../helpers/backend_helper';
// import images
import logoActive from '../../assets/images/logo-active.png';
//Import config

const ChangePasswordPage = (props) => {
  const [userType] = useState('primary');
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    let user = {
      email: values.email,
      otp: values.otp,
    };
    if (userType === 'secondary') {
      user['alias'] = values.alias;
    }
    setLoading(true);
    postChangePasswordOtp(values)
      .then((response) => {
        if (response.codes === 200) {
          response.data['userType'] =
            response.data.userType !== undefined ? response.data.userType : 'P';
          localStorage.setItem('authUser', JSON.stringify(response));
          props.history.push('/changePassword');
          setLoading(false);
        } else {
          setError(response.message);
          setLoading(false);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    document.body.className = 'authentication-bg';
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = '';
    };
  });

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody className="p-4">
                  <Row>
                    <Col lg={12}>
                      <div className="text-center">
                        <Link to="/" className="my-2 d-block auth-logo">
                          <img
                            src={logoActive}
                            alt=""
                            height="50"
                            className="logo logo-dark"
                          />
                        </Link>
                      </div>
                    </Col>
                  </Row>
                  <div className="p-2 mt-4">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      {error && typeof error === 'string' ? (
                        <Alert color="danger">{error}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3 position-relative">
                        <div className="float-end">
                          <Link to="/client-list" className="text-muted">
                            Go back to dashboard?
                          </Link>
                        </div>
                        <div className="mt-3">
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? (
                              <span>
                                <Spinner
                                  className="spinner-border-sm mr-5"
                                  color="white"
                                />{' '}
                                Loading...
                              </span>
                            ) : (
                              'Submit'
                            )}
                          </button>
                        </div>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ChangePasswordPage);
