import {
  GET_CART_DATA,
  GET_CART_DATA_FAIL,
  GET_CART_DATA_SUCCESS,
  GET_CLIENTS,
  GET_CUSTOMERS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_SUCCESS,
  GET_ORDERS_FAIL,
  GET_ORDERS_SUCCESS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCT_DETAIL,
  GET_PRODUCT_DETAIL_FAIL,
  GET_PRODUCT_DETAIL_SUCCESS,
  GET_SHOPS_FAIL,
  GET_SHOPS_SUCCESS
} from "./actionTypes";

// export const getProducts = () => ({
//   type: GET_PRODUCTS,
// })

export const getProductsSuccess = (products) => ({
  type: GET_PRODUCTS_SUCCESS,
  payload: products,
});

export const getProductsFail = (error) => ({
  type: GET_PRODUCTS_FAIL,
  payload: error,
});

export const getProductDetail = (productId) => ({
  type: GET_PRODUCT_DETAIL,
  productId,
});

export const getProductDetailSuccess = (products) => ({
  type: GET_PRODUCT_DETAIL_SUCCESS,
  payload: products,
});

export const getProductDetailFail = (error) => ({
  type: GET_PRODUCT_DETAIL_FAIL,
  payload: error,
});

export const getClients = () => ({
  type: GET_CLIENTS,
});

export const getOrdersSuccess = (clients) => ({
  type: GET_ORDERS_SUCCESS,
  payload: clients,
});

export const getOrdersFail = (error) => ({
  type: GET_ORDERS_FAIL,
  payload: error,
});

export const getCartData = () => ({
  type: GET_CART_DATA,
});

export const getCartDataSuccess = (cartData) => ({
  type: GET_CART_DATA_SUCCESS,
  payload: cartData,
});

export const getCartDataFail = (error) => ({
  type: GET_CART_DATA_FAIL,
  payload: error,
});

export const getCustomers = () => ({
  type: GET_CUSTOMERS,
});

export const getCustomersSuccess = (customers) => ({
  type: GET_CUSTOMERS_SUCCESS,
  payload: customers,
});

export const getCustomersFail = (error) => ({
  type: GET_CUSTOMERS_FAIL,
  payload: error,
});

// export const getShops = () => ({
//   type: GET_SHOPS,
// });

export const getShopsSuccess = (shops) => ({
  type: GET_SHOPS_SUCCESS,
  payload: shops,
});

export const getShopsFail = (error) => ({
  type: GET_SHOPS_FAIL,
  payload: error,
});
