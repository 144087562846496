import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { toast } from 'react-toastify';

import DownloadIcon from '@mui/icons-material/Download';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Loader from '../../components/Common/Loader';
import { MainStyle, RootStyle } from '../../components/Common/Style';
import DashboardUINav from '../../components/Navbar/DashboardUINav/DashboardUINav';
import {
  getConsent,
  getDashboard,
  postStatement,
} from '../../helpers/backend_helper';
import {
  MainCardStyled,
  OverviewWrapper,
  PageBoxWrapper,
  TableCellData,
  TableCellHead,
} from './StatementStyle';

function createData(Item) {
  return { Item };
}
const rows = [
  createData('Basic Statements '),
  createData('Statement Plus '),
  createData('Real Time Balance '),
  createData('Real Time Balance '),
  createData('Full Affordability Report '),
];

const Statement = () => {
  let location = useLocation();
  let history = useHistory();
  const [dashboardData, setDashboardData] = useState();
  const [consent, setConsent] = useState();
  const [accountsNumberList, setAccountsNumberList] = useState([]);
  const [selectAccount, setSelectAccount] = useState();
  const [statementAccessData, setStatementAccessData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [consents, setConsents] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let userData = JSON.parse(localStorage.getItem('<<<user-info>>>'));

  useEffect(async () => {
    setLoader(true);
    let allProducts = [];
    let hasAccessStatement = false;
    let endDate = moment().format('YYYY-MM-DD');
    await getConsent(location).then((res) => {
      if (res.codes == 200) {
        if (res.data?.length) {
          let accountNumberList = [];
          setConsents(res?.data);
          res?.data.map((v) => {
            if (
              v.consent.data.products.filter(
                (item) =>
                  item.code === 'statement_basic' ||
                  item.code === 'statement_plus' ||
                  item.code === 'affordability_full'
              ).length
            )
              if (v?.accountNumber) {
                setSelectedDate(v);
                accountNumberList.push({
                  title: `XXXXXX${v.accountNumber.slice(-4)}`,
                  value: v.accountNumber,
                });
              }
          });
          setAccountsNumberList(accountNumberList);
          res.data?.forEach((v) => {
            if (
              v?.consent?.data?.products.length &&
              v?.consent?.data?.products &&
              v?.consent?.data?.accountNumber ===
                (selectAccount || accountNumberList[0]?.value)
            ) {
              v?.consent?.data?.products.forEach((i) => {
                if (i.group === 'affordability' || i.group === 'statement') {
                  i.months.forEach((j) => {
                    let obj = {
                      accountNumber: v.accountNumber,
                      statementMonth: j,
                    };
                    allProducts.push(obj);
                  });
                }
              });
            }
          });
          setStatementAccessData(allProducts);

          if (!accountNumberList.length) {
            history.push('/kyc', { ...location?.state });
          } else {
            hasAccessStatement = false;
          }
          setConsent(res.data);
        }
        setLoader(false);
      } else {
        setLoader(false);
        toast.error(
          res?.response?.data?.message || res.message || 'Something went wrong!'
        );
      }
    });
  }, [selectAccount]);
  useEffect(() => {
    let endDate = moment().format('YYYY-MM-DD');
    let selectAccountNumber = selectAccount
      ? selectAccount
      : accountsNumberList[0]?.value;
    let selectedConsent = consents?.find((v) => {
      return v?.accountNumber === selectAccountNumber;
    });
    setSelectedDate(selectedConsent);
    let dashboardPayload = {
      userId: location?.state?.client,
      date_to: moment(selectedConsent?.consent?.data?.dateTo).format(
        'YYYY-MM-DD'
      ),
      n_months: 12,
      account_id: selectAccount ? selectAccount : accountsNumberList[0]?.value,
    };
    // if (hasAccessStatement) {
    getDashboard(dashboardPayload).then((res) => {
      if (res?.codes === 200) {
        setDashboardData(res);
        let selectedProduct = res?.data?.find(
          (item) => item.accountNumber === selectAccount
        );

        if (selectedProduct && selectAccount?.length) {
          endDate = moment(selectedProduct?.consent?.data?.dateTo).format(
            'YYYY-MM-DD'
          );
        } else {
          let allEndDate = res?.data.map((v) => v.consent?.data?.dateTo);
          let maxEndDate =
            allEndDate.length &&
            allEndDate.reduce(function (a, b) {
              return a > b ? a : b;
            });
          endDate = moment(maxEndDate).format('YYYY-MM-DD');
        }
        setLoader(false);
      } else {
        setLoader(false);
        toast.error(
          res?.response?.data?.message || res.message || 'Something went wrong!'
        );
      }
    });
    // }
  }, [selectAccount, accountsNumberList, consent]);
  const handleDownload = async (rowValue, type) => {
    let payload = {
      accountNumber: rowValue?.accountNumber,
      monthYear: rowValue?.statementMonth,
      userId: dashboardData.data.userId,
      type: type,
    };
    const url = type === 'pdf' ? 'get-statement-url' : 'get-csv-statement-url';
    await postStatement(payload, url).then((res) => {
      if (res && res.codes === 200) {
        window.open(res.data?.url);
      }
    });
  };
  return (
    <RootStyle>
      <DashboardUINav
        dashboardData={dashboardData}
        consent={consent}
        location={location}
      />
      <MainStyle>
        {!loader ? (
          <>
            <OverviewWrapper>
              <PageBoxWrapper>
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ mt: { lg: 5, xs: 15 } }}
                >
                  <Box sx={{ flexGrow: 1 }} />
                  <Box sx={{ flexGrow: 2, mt: { sm: 0, xs: 5 }, mb: 5 }}>
                    <Grid container gap={2} justifyContent="flex-end">
                      <Grid item md={2.5} xs={12}>
                        <Typography
                          variant="Body_medium_16"
                          color="orange.main"
                        >
                          Account Number
                        </Typography>
                        <Autocomplete
                          id="select-business-partner"
                          options={accountsNumberList}
                          getOptionLabel={(option) => option.title}
                          // PaperComponent={CustomPaper}
                          value={
                            accountsNumberList?.find(
                              (item) => item.value === selectAccount
                            ) || accountsNumberList[0]
                          }
                          renderInput={(params) => (
                            <TextField
                              variant="filled"
                              id="filled-hidden-label-normal"
                              placeholder="Select..."
                              inputProps={{ 'aria-label': 'Without label' }}
                              hiddenLabel
                              fullWidth
                              {...params}
                            />
                          )}
                          onChange={(e, value) => {
                            setSelectAccount(value?.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Stack>
                <MainCardStyled>
                  <TableContainer>
                    <Table
                      stickyHeader
                      sx={{
                        minWidth: 350,
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCellHead>Statement List</TableCellHead>
                          <TableCellHead align="right">Action</TableCellHead>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {statementAccessData?.map((row, index) => (
                          <TableRow hover key={index}>
                            {row?.accountNumber && row?.statementMonth && (
                              <>
                                <TableCellData>
                                  {' '}
                                  {row?.statementMonth}
                                </TableCellData>
                                <TableCellData align="right">
                                  <Button
                                    id="basic-button"
                                    aria-controls={
                                      open ? 'basic-menu' : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                    endIcon={
                                      <DownloadIcon
                                        sx={{
                                          fill: (theme) =>
                                            theme.palette.orange.main,
                                        }}
                                      />
                                    }
                                  >
                                    Download
                                  </Button>
                                  <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                      'aria-labelledby': 'basic-button',
                                    }}
                                  >
                                    <MenuItem
                                      onClick={() => handleDownload(row, 'csv')}
                                    >
                                      CSV
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => handleDownload(row, 'pdf')}
                                    >
                                      PDF
                                    </MenuItem>
                                  </Menu>
                                </TableCellData>
                                {/* <TableCellData
                                  align="center"
                                  onClick={() => handleDownload(row)}
                                >
                                  <Stack
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    sx={{ cursor: 'pointer' }}
                                    gap={1}
                                  >
                                    <Typography variant="Body_medium_14">
                                      Download
                                    </Typography>
                                    <DownloadIcon
                                      sx={{
                                        fill: (theme) =>
                                          theme.palette.orange.main,
                                      }}
                                    />
                                  </Stack>
                                </TableCellData> */}
                              </>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </MainCardStyled>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    opacity: '0.5',
                  }}
                >
                  Data Fetch from{' '}
                  {moment(selectedDate?.consent?.data?.dateFrom).format(
                    'DD/MM/YYYY'
                  )}{' '}
                  to{' '}
                  {moment(selectedDate?.consent?.data?.dateTo).format(
                    'DD/MM/YYYY'
                  )}
                </Box>
              </PageBoxWrapper>
            </OverviewWrapper>
          </>
        ) : (
          <Loader />
        )}
      </MainStyle>
    </RootStyle>
  );
};

export default Statement;
