import PropTypes from "prop-types";
import { forwardRef, useEffect, useState } from "react";

import {
  Box,
  Dialog,
  DialogContent,
  Slide,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from "@mui/material";
import BackIcon from "../../../assets/images/BackIcon.svg";
// import { PermissionsBox, PermissionsDetailsBox } from "./PlansStyle";

import moment from "moment";
import {
  TableCellData,
  TableCellHead,
  TableRows,
} from "../../../components/Common/Table";
import {
  MainTitle,
  TableDataWrapper,
  TotalCard
} from "./TabStytle";

// animation
const Transition = forwardRef((props, ref) => (
  <Slide direction="left" ref={ref} {...props} />
));

function createData(tmonth, tran_amount) {
  return { tmonth, tran_amount };
}
const rows = [
  createData("January", "₦ 500,000.00"),
  createData("February", "₦ 500,000.00"),
  createData("March", "₦ 500,000.00"),
  createData("April", "₦ 500,000.00"),
  createData("May", "₦ 500,000.00"),
  createData("June", "₦ 500,000.00"),
  createData("July", "₦ 500,000.00"),
  createData("August", "₦ 500,000.00"),
  createData("September", "₦ 500,000.00"),
  createData("October", "₦ 500,000.00"),
  createData("November", "₦ 500,000.00"),
  createData("December", "₦ 500,000.00"),
];

const MonthlyExpenses = ({ open, handleCloseDialog, dashboardData }) => {
  let [MonthlyExpenses, setMonthlyExpenses] = useState([]);
  let [totalExpense, setTotalExpense] = useState(0);
  useEffect(() => {
    if (dashboardData && dashboardData.length) {
      let getData = dashboardData.filter((item) => {
        return (
          item.tran_type === "E" &&
          item.category_lvl1 === "Expense" &&
          item.aggregation_lvl === 1
        );
      });
      setMonthlyExpenses(getData);
      if (getData.length) {
        const result = getData.reduce(
          (total, thing) => total + thing.tran_amount,
          0
        );
        setTotalExpense(result || 0);
      }
    }
  }, [open]);
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      sx={{
        "&>div:nth-of-type(3)": {
          justifyContent: "flex-end",
          "&>div": {
            m: 0,
            borderRadius: "0px",
            width: "100%",
            maxHeight: "100%",
            height: "100%",
            backgroundColor: theme.palette.primary.light,
            padding: 0,

            [theme.breakpoints.up("lg")]: {
              width: "50%",
              padding: theme.spacing(5, 2, 5, 2),
            },
          },
        },
      }}
    >
      {open && (
        <>
          <DialogContent mb={2}>
            <Stack
              direction="row"
              alignItems="center"
              mb={2}
              sx={{ cursor: "pointer" }}
              onClick={handleCloseDialog}
            >
              <Box>
                <Box component="img" src={BackIcon} alt="back" />
              </Box>
              <Typography variant="Body_medium_12" color="grey.dark" ml={1.25}>
                Back
              </Typography>
            </Stack>

            {/*  */}
            <Box mb={4}>
              <MainTitle>Expenses </MainTitle>
            </Box>

            <TableDataWrapper>
              <Box width={500}>
                <TableContainer>
                  <Table
                    sx={{
                      overflowX: "hidden",
                      minWidth: 300,
                      height: "max-content",
                    }}
                  >
                    <TableHead>
                      <TableRows>
                        <TableCellHead>Monthly</TableCellHead>
                        <TableCellHead>Confident Level</TableCellHead>
                        <TableCellHead>Amount</TableCellHead>
                      </TableRows>
                    </TableHead>
                    <TableBody>
                      {MonthlyExpenses?.map((row) => (
                        <TableRow hover key={row.tmonth}>
                          <TableCellData>
                            {moment(row.tmonth).format("MMMM")}
                          </TableCellData>
                          <TableCellData>
                            {row?.confidence_level
                              ? (row?.confidence_level * 100).toFixed(2)
                              : 0}
                            %
                          </TableCellData>
                          <TableCellData>
                            ₦{" "}
                            {(row.tran_amount || 0).toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}
                          </TableCellData>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TotalCard my={2}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="Body_medium_20" color="orange.main">
                      Total{" "}
                    </Typography>
                    <Typography variant="Body_semibold_18">
                      ₦{" "}
                      {(totalExpense || 0).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}
                    </Typography>
                  </Stack>
                </TotalCard>
              </Box>

              {/* <YearlyCard>
                <Typography>Yearly</Typography>
                <StyledDivider2 />

                <Stack direction="column" gap={1.5}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="Body_medium_12" color="orange.main">
                      2022
                    </Typography>
                    <Typography variant="Body_medium_12">
                      ₦ 4,800,000.00
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="Body_medium_12" color="orange.main">
                      2021
                    </Typography>
                    <Typography variant="Body_medium_12">
                      ₦ 3,540,000.00
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="Body_medium_12" color="orange.main">
                      2020
                    </Typography>
                    <Typography variant="Body_medium_12">
                      ₦ 5,100,000.00
                    </Typography>
                  </Stack>
                </Stack>
              </YearlyCard> */}
            </TableDataWrapper>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

MonthlyExpenses.propTypes = {
  open: PropTypes.bool,
  handleCloseDialog: PropTypes.func,
};

export default MonthlyExpenses;
