import { all, call, fork, put, takeEvery } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserFailed, registerUserReset, registerUserSuccessful } from "./actions"

//Include Both Helper File with needed methods
import { postJwtRegister } from '../../../helpers/backend_helper';


// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user, history } }) {
  try {
    const response = yield call(postJwtRegister, user);
    if (response.codes === 200) {
      let id = response.message.match(/(\d+)/);
      localStorage.setItem('userId', id);
      yield put(registerUserSuccessful(response));
      yield put(registerUserReset());
      history.push('/registration-success');
    } else yield put(registerUserFailed(response));
  } catch (error) {
    yield put(registerUserFailed(error));
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
