import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Select from 'react-select';
import { Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import Table from '../../components/Table/index';
import Apaexlinecolumn from './apaexlinecolumn';
import DonutChart from './dountchart';

import {
  getAccountByBank,
  getBankList,
  getDashboardDetail,
} from '../../helpers/backend_helper';

const Dashboard = () => {
  const params = useParams();

  const [total] = useState(5);
  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState();
  const [month, setMonth] = useState(6);
  const [bankList, setBankList] = useState();
  const [accountId, setAccountId] = useState();
  const [bankName, setBankName] = React.useState();

  const urlParams = new URLSearchParams(window.location.search);
  const clientName = urlParams.get('name');
  const jwt = urlParams.get('jwt');

  useEffect(() => {
    if (jwt) {
      localStorage.setItem(
        'authUser',
        JSON.stringify({
          data: {
            accessToken: jwt,
            userId: params.id,
          },
        })
      );
    }
    if (params.id) {
      setLoading(true);
      getBankList(params.id).then((res) => {
        if (res.codes === 200) {
          setBankList(res.data);
          let bank = res.data[0].bankName;
          setBankName(bank);
          getAccountByBank({
            bankName: res.data[0].bankName,
            userId: parseInt(params.id),
          }).then((response) => {
            if (response.codes === 200) {
              setAccountId(response.data.Data.Account[0].AccountId);
              GetDashboardDetail(
                bank,
                response.data.Data.Account[0].AccountId,
                month
              );
            } else {
              setLoading(false);
            }
          });
        } else {
          setLoading(false);
        }
      });
    }
  }, [params.id]);

  const defaultSort = 'userFirstName';
  const defaultSortDirection = 'ASC';
  const [pageOption, setPageOption] = useState({
    page: 1,
    limit: 1000,
    sortBy: '',
    sortDirection: '',
    search: '',
  });

  const [orderList, setOrderList] = useState([]);

  const columns = [
    {
      dataField: 'group',
      text: 'Group',
      sort: true,
      formatter: (row) => {
        return (
          <Link to="/client/dashboard" className="text-dark fw-bold">
            {`${row}`}
          </Link>
        );
      },
      footer: 'Total',
    },
    {
      dataField: 'income',
      text: 'Income',
      sort: true,
      formatter: (val) => val.toFixed(2),
      footer: (columnData) =>
        columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
    },
    {
      dataField: 'expense',
      text: 'Expense',
      sort: false,
      formatter: (val) => val.toFixed(2),
      footer: (columnData) =>
        columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
    },
  ];

  const [tableArray, setTableArray] = React.useState([]);

  React.useEffect(() => {
    if (detailData) {
      const uniqueGrouping = [
        ...new Set(
          detailData.Transaction.map(
            (item) => item.TranscationCategory.grouping
          )
        ),
      ];

      let arr = [];
      uniqueGrouping.map((i, j) => {
        let income = 0;
        let expense = 0;
        detailData.Transaction.map((item, index) => {
          if (i === item.TranscationCategory.grouping) {
            if (item.Income) {
              income = income + parseFloat(item.Amount.Amount);
            } else {
              expense = expense + parseFloat(item.Amount.Amount);
            }
          }
        });

        let newObj = { group: i, income: income, expense: expense };

        arr.push(newObj);

        setTableArray(arr);
      });
    }
  }, [detailData]);

  React.useEffect(() => {
    setOrderList(tableArray);
  }, [tableArray]);

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText }
  ) => {
    if (type === 'search' && searchText !== '') {
      const data = {
        nodes: tableArray.filter((item) =>
          item.group.toLowerCase().includes(searchText.toLowerCase())
        ),
      };
      setOrderList(data.nodes);
    } else {
      setOrderList(tableArray);
    }
  };

  const [bankObj, setbankObj] = useState();

  function bankNameChange(data) {
    setBankName(data.bankName);
    setbankObj(data);
    getAccountByBank({
      bankName: data.bankName,
      userId: parseInt(params.id),
    }).then((res) => {
      if (res.codes === 200) {
        GetDashboardDetail(res.data[0].bankName, accountId, month);
      }
    });
  }
  const [monthObj, setmonthObj] = useState({ label: '6 Months', value: 6 });

  function durationChange(data) {
    setMonth(data.value);
    setmonthObj(data);
    if (bankName && accountId) {
      GetDashboardDetail(bankName, accountId, data.value);
    }
  }

  function GetDashboardDetail(bankName, accountId, duration) {
    let data = {
      userId: parseInt(params.id),
      bankName: bankName,
      accountId: accountId,
      durationInMonths: duration,
    };
    getDashboardDetail(data).then((res) => {
      if (res.codes === 200) {
        setDetailData(res.data);

        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {detailData && !loading && (
          <Container fluid>
            <Row className="mb-3">
              <Col className="col-3">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">{clientName}</h4>
                </div>
              </Col>
              <Col className="col-9">
                <div className="float-end">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="dashboard-select">
                        <Select
                          value={monthObj}
                          placeholder="Duration"
                          containerClass="form-control"
                          options={[
                            { label: '1 Month', value: 1 },
                            { label: '3 Months', value: 3 },
                            { label: '6 Months', value: 6 },
                          ]}
                          onChange={(data) => durationChange(data)}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="dashboard-select">
                        <Select
                          value={bankObj}
                          placeholder="Bank"
                          containerClass="form-control"
                          options={bankList}
                          getOptionLabel={(option) => option.bankName}
                          getOptionValue={(option) => option.bankName}
                          onChange={(data) => bankNameChange(data)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xl={6}>
                <Card style={{ height: '500px' }}>
                  <CardBody className="dashboard">
                    <CardTitle className="mb-4">Transaction Groups</CardTitle>
                    <Table
                      keyField="id"
                      data={orderList}
                      sizePerPage={pageOption.limit}
                      page={pageOption.page}
                      columns={columns}
                      onTableChange={handleTableChange}
                      noDataIndication={'No Data Found'}
                      loading={loading}
                      isPagination={false}
                      isSearch={true}
                      totalSize={total}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Card style={{ height: '500px' }}>
                  <CardBody>
                    <CardTitle className="mb-4">Disposable Income</CardTitle>
                    <DonutChart detailData={detailData} month={month} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      {' '}
                      Monthly Transactions{' '}
                    </CardTitle>
                    <Apaexlinecolumn detailData={detailData} month={month} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}

        <Container fluid>
          {loading && <div className="fetchingData">Fetching data...</div>}
          {!detailData && !loading && (
            <div className="fetchingData">No data found!</div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
