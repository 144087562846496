import {
  AvFeedback,
  AvField,
  AvForm,
  AvGroup,
} from 'availity-reactstrap-validation';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import logoActive from '../../assets/images/logo-active.png';
import { postChangePassword } from '../../helpers/backend_helper';

const ChangePassword = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [oldPassword, setCurrentPassword] = useState('');
  const [forgetError, setForgetError] = useState('');
  const [forgetSuccessMsg, setForgetSuccessMsg] = useState('');
  const [otp, setOtp] = useState('');

  useEffect(() => {
    document.body.className = 'authentication-bg';
    return function cleanup() {
      document.body.className = '';
    };
  }, []);

  function handleValidSubmit(event, values) {
    setLoading(true);
    const data = {
      oldPassword,
      newPassword: values.newPassword,
      otp,
    };

    postChangePassword(data)
      .then((res) => {
        if (res.codes === 200) {
          setForgetSuccessMsg(res.message);
          setLoading(false);
          setTimeout(() => {
            history.push('/login');
          }, 1500);
        } else if (res.codes === 400 || res.code === 401) {
          setForgetError(res.message);
          setLoading(false);
        } else if (res.codes === 401) {
          setForgetError(res.message);
          setLoading(false);
        } else if (res.codes === 500) {
          setForgetError('Internal Server Error. Please try again later.');
          setLoading(false);
        }
      })
      .catch(() => {
        setForgetError('Something went wrong. Please try again.');
        setLoading(false);
      });
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody className="p-4">
                  <RouterLink to="/" className="my-2 d-block auth-logo">
                    <img
                      src={logoActive}
                      alt=""
                      height="50"
                      className="logo logo-dark"
                    />
                  </RouterLink>
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Change Password</h5>
                  </div>
                  <div className="p-2 mt-4">
                    {forgetError && forgetError ? (
                      <Alert
                        color="danger"
                        className="text-center mb-4"
                        style={{ marginTop: '13px' }}
                      >
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert
                        color="success"
                        className="text-center mb-4"
                        style={{ marginTop: '13px' }}
                      >
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      <div className="mb-3">
                        <AvGroup>
                          <Label for="otp">OTP</Label>
                          <AvField
                            type="text"
                            name="otp"
                            id="otp code"
                            placeholder="Enter OTP"
                            onChange={(e) => setOtp(e.target.value)}
                            required
                          />
                          <AvFeedback>Please enter the OTP.</AvFeedback>
                        </AvGroup>
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="oldPassword"
                          type="password"
                          label="Current Password"
                          placeholder="Current Password"
                          errorMessage="Enter current password"
                          validate={{ required: { value: true } }}
                          value={oldPassword}
                          onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="newPassword"
                          type="password"
                          label="New Password"
                          placeholder="New Password"
                          errorMessage="Enter new password"
                          validate={{ required: { value: true } }}
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="password1"
                          type="password"
                          label="Confirm Password"
                          placeholder="Confirm Password"
                          errorMessage="Password and confirm password must be the same"
                          validate={{
                            required: { value: true },
                            match: { value: 'newPassword' },
                          }}
                        />
                      </div>
                      <Row className="row mb-0">
                        <Col className="col-12 text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            {loading ? (
                              <span>
                                <Spinner
                                  className="spinner-border-sm mr-5"
                                  color="white"
                                />{' '}
                                Loading...
                              </span>
                            ) : (
                              'Change Password'
                            )}
                          </button>
                        </Col>
                      </Row>
                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          {' '}
                          <RouterLink
                            to="/client-list"
                            className="fw-medium text-primary"
                          >
                            {' '}
                            Go Back{' '}
                          </RouterLink>
                        </p>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ChangePassword.propTypes = {};

export default ChangePassword;
