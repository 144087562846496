import AddIcon from '@mui/icons-material/Add';
import { Button, Grid, Stack, Typography } from '@mui/material';

// import Transaction from './transaction';
import { useEffect, useState } from 'react';
import MainCard from '../../components/Common/MainCard';
import { useTheme } from '@emotion/react';
import { toast } from 'react-toastify';
import { getWalletDetails } from '../../helpers/backend_helper';
import { Container } from 'reactstrap';
import Transaction from './transaction';
import AddUnitModal from './AddUnitModal';
// import UnitDetailsModel from './unitDetailsModel';

const Wallet = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [walletDetails, setWalletDetails] = useState([]);
  const theme = useTheme();
  const handleManagePopup = () => {
    setIsOpen(!isOpen);
  };
  const fetchWalletData = async () => {
    await getWalletDetails()
      .then((res) => {
        setWalletDetails(res.data?.products || []);
      })
      .catch((err) => {
        toast.error('Something went wrong!');
      });
  };
  useEffect(() => {
    fetchWalletData();
  }, []);
  const newLocal = (
    <Stack
      direction={'row'}
      flexWrap={'wrap'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Typography variant="Body_light_20" fontWeight={600}>
        My Wallet
      </Typography>
      <Button
        color="secondary"
        sx={{ width: 'fit-content' }}
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={handleManagePopup}
      >
        Add Unit
      </Button>
    </Stack>
  );
  return (
    <>
      <div className="page-content">
        <Container fluid>
          {newLocal}

          <Grid container spacing={2} my={0.5}>
            {walletDetails.length ? (
              <>
                {walletDetails.map((item) => (
                  <Grid item md={4} xs={12}>
                    <MainCard sx={{ boxShadow: 'none' }}>
                      <Stack direction={'column'} gap={1.5}>
                        <Typography
                          variant="Body_semibold_16"
                          color="text.orange"
                        >
                          {item?.productName}
                        </Typography>
                        <Stack
                          direction={'row'}
                          alignItems={'baseline'}
                          gap={1}
                        >
                          <Typography variant="Body_medium_32" fontWeight={700}>
                            {item?.walletBalance}
                          </Typography>
                          <Typography
                            variant="Body_medium_14"
                            sx={{ color: '#00182399' }}
                          >
                            Units
                          </Typography>
                        </Stack>
                      </Stack>
                    </MainCard>
                  </Grid>
                ))}
              </>
            ) : (
              <></>
            )}
          </Grid>
          <AddUnitModal isOpen={isOpen} setIsOpen={setIsOpen} />
          <Transaction />
        </Container>
      </div>
    </>
  );
};

export default Wallet;
