import PropTypes from "prop-types";
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

// material-ui
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Card,
  Container,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import MainCard from '../../components/Common/MainCard';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ReactComponent as CheckIcon } from '../../assets/images/checkIcon.svg';
import { ReactComponent as FailedIcon } from '../../assets/images/failed-payment.svg';
import { ReactComponent as UpcomingIcon } from '../../assets/images/upcoming-payment.svg';

// assets
import DeleteIcon from '@mui/icons-material/Delete';
// import getAxiosInstance from 'api';
import { useEffect, useState } from 'react';
import {
  getMandateList,
  getPaymentSummary,
} from '../../helpers/backend_helper';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

// table header
const headCells = [
  {
    id: '',
    numeric: true,
    disablePadding: false,
    label: 'Mandate Name',
  },
  {
    id: 'lastPaymentDate',
    numeric: true,
    disablePadding: false,
    label: 'Last Payment Date',
  },
  {
    id: 'Frequency',
    numeric: true,
    disablePadding: false,
    label: 'Interval',
  },

  {
    id: 'nextPaymentDate',
    numeric: true,
    disablePadding: false,
    label: 'Next Payment Date',
  },
  {
    id: '',
    numeric: true,
    disablePadding: false,
    width: '0.5px',
    label: '',
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  border: '1px solid #DEDEDE',
  color: '#212120',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '500',
}));

// ==============================|| TABLE - HEADER ||============================== //

function EnhancedTableHead({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
    backgroundColor: '#F0F0F0',
    color: 'rgba(33, 33, 32, 0.60)',
    border: '1px solid #DEDEDE',
  }));

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCellHead
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : undefined}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCellHead>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// ==============================|| TABLE - TOOLBAR ||============================== //

const EnhancedTableToolbar = ({ numSelected }) => (
  <Toolbar
    sx={{
      p: 0,
      pl: 1,
      pr: 1,
      ...(numSelected > 0 && {
        color: (theme) => theme.palette.secondary.main,
      }),
    }}
  >
    {numSelected > 0 ? (
      <Typography color="inherit" variant="subtitle1">
        {numSelected} selected
      </Typography>
    ) : (
      <Typography variant="h4" id="tableTitle">
        Nutrition
      </Typography>
    )}
    <Box sx={{ flexGrow: 1 }} />
    {numSelected > 0 && (
      <Tooltip title="Delete">
        <IconButton size="large">
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    )}
  </Toolbar>
);

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

// ==============================|| TABLE - ENHANCED ||============================== //

export default function CustomerInfo({ appName }) {
  const theme = useTheme();
  const location = useLocation();
  const history = useHistory();
  const passedState = location.state;
  const userData = JSON.parse(localStorage.getItem('userData'));
  const [order, setOrder] = useState('DESC');
  const [orderBy, setOrderBy] = useState('id');
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [dense] = useState(false);
  const [limit, setLimit] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [apiList, setApiList] = useState([]);
  const [total, setTotal] = useState();
  const [isFilter, setIsFilter] = useState(true);
  const [customerList, setCustomerList] = useState([]);
  const [customerTotal, setCustomerTotal] = useState(0);
  const [summary, setSummary] = useState({});

  const fetchCustomerList = async () => {
    const payload = {
      page: page + 1,
      limit: limit,
      sortBy: orderBy,
      sortDirection: order,
      search: '',
      filter: {
        userEmail: 'pokedex2k17@gmail.com',
      },
    };
    await getMandateList(payload)
      .then((res) => {
        setCustomerList(res.data.items || []);
        setCustomerTotal(res.data.total);
      })
      .catch((err) => {
        toast.error('Something went wrong!');
      });
  };
  const fetchPaymentSummary = async () => {
    await getPaymentSummary()
      .then((res) => {
        setSummary(res.data);
      })
      .catch((err) => {
        toast.error('Something went wrong!');
      });
  };

  useEffect(() => {
    fetchCustomerList();
    fetchPaymentSummary();
  }, [limit, page, order, orderBy]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'ASC';
    setOrder(isAsc ? 'DESC' : 'ASC');
    setOrderBy(property);
  };
  const isSelected = () => {};

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(event.target.value);
  };

  return (
    <Container fluid>
      <Box mt={14}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography variant="Body_light_20" fontWeight={600}>
              Direct Debit
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack direction={'column'} gap={2}>
              <Stack direction={'row'} alignItems={'center'} gap={3}>
                <IconButton
                  sx={{
                    background: theme.palette.orange.main,
                    ':hover': { background: theme.palette.orange.main },
                  }}
                >
                  <ArrowBackIcon
                    onClick={() => history.goBack()}
                    sx={{ color: theme.palette.background.paper }}
                  />
                </IconButton>
                <Typography variant="Body_medium_28">
                  {passedState?.state?.userName}
                </Typography>
              </Stack>
              <Stack direction={'row'} alignItems={'center'}>
                <Typography variant="Body_medium_16" sx={{ width: '100px' }}>
                  Email:
                </Typography>
                <Typography variant="Body_medium_16">
                  {passedState?.state?.userEmail}
                </Typography>
              </Stack>

              {/* <Stack direction={'row'} alignItems={'center'}>
                <Typography variant="Body_medium_16" sx={{ width: '100px' }}>
                  Created:
                </Typography>
                <Typography variant="Body_medium_16">
                  Apr 02 , 2024 11:40 AM
                </Typography>
              </Stack> */}
            </Stack>
          </Grid>

          <Grid item xs={12} md={4}>
            <MainCard>
              <Stack
                direction="row"
                justifyContent="space-between"
                gap={1}
                alignItems={'center'}
              >
                <UpcomingIcon />
                <Stack direction="column" gap={2}>
                  <Typography variant="Body_medium_18">
                    Total Upcoming Payments{' '}
                  </Typography>
                  <Typography variant="Body_semibold_28">
                    {' '}
                    ₦{summary?.totalUpcomingAmount}
                  </Typography>
                  <Box />
                </Stack>
              </Stack>
            </MainCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <MainCard>
              <Stack
                direction="row"
                justifyContent="space-between"
                gap={1}
                alignItems={'center'}
              >
                <FailedIcon />
                <Stack direction="column" gap={2}>
                  <Typography variant="Body_medium_18">
                    Total Failed Payments{' '}
                  </Typography>
                  <Typography variant="Body_semibold_28">
                    {' '}
                    ₦{summary?.totalFailedAmount}
                  </Typography>
                  <Box />
                </Stack>
              </Stack>
            </MainCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <MainCard>
              <Stack
                direction="row"
                justifyContent="space-between"
                gap={1}
                alignItems={'center'}
              >
                <CheckIcon />
                <Stack direction="column" gap={2}>
                  <Typography variant="Body_medium_18">
                    Total Collected Payments
                  </Typography>
                  <Typography variant="Body_semibold_28">
                    {' '}
                    ₦{summary?.totalCollectionAmount}
                  </Typography>
                  <Box />
                </Stack>
              </Stack>
            </MainCard>
          </Grid>
        </Grid>

        <Card sx={{ mt: 2 }}>
          <>
            {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
            {/* table */}
            <TableContainer>
              <Table
                sx={{
                  minWidth: 750,
                  backgroundColor: 'white',
                }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  // onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={total}
                />
                <TableBody>
                  {customerList.length ? (
                    <>
                      {customerList.map((row, index) => {
                        if (typeof row === 'number') return null;
                        const isItemSelected = isSelected(row.name);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.name)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.name}
                            selected={isItemSelected}
                          >
                            <StyledTableCell id={labelId}>
                              {row?.reasonForPayment}
                            </StyledTableCell>
                            <StyledTableCell>
                              {moment(row?.createdAt).format(
                                'MMM DD ,YYYY hh:mm A'
                              )}
                            </StyledTableCell>
                            <StyledTableCell>{row?.frequency}</StyledTableCell>
                            <StyledTableCell>
                              {moment(row?.nextPaymentDate).format(
                                'MMM DD ,YYYY hh:mm A'
                              )}
                            </StyledTableCell>
                            <StyledTableCell>
                              <MoreVertIcon />
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {/* table pagination */}
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={customerTotal}
              rowsPerPage={limit}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        </Card>
      </Box>
    </Container>
  );
}
