import styled from "@emotion/styled";
import PasswordIcon from "@mui/icons-material/Password";
import { Box, MenuItem, Stack, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as UserSvg } from "../../../assets/images/User2.svg";
import { ReactComponent as ArrowDownSvg } from "../../../assets/images/caret-down.svg";
import { ReactComponent as SignOutSvg } from "../../../assets/images/power-off.svg";
import MenuPopover from "../../Common/MenuPopover";

// ----------------------------------------------------------------------

const SignOut = styled(SignOutSvg)(({ theme }) => ({
  width: 24,
  height: 24,
  path: {
    fill: theme.palette.grey.main,
  },
}));

const User = styled(UserSvg)(({ theme }) => ({
  width: 34,
  height: 34,
  path: {
    fill: theme.palette.common.white,
  },
}));

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(1, 2.5),
}));

const MenuItemWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItem: "center",
  gap: theme.spacing(2),
  ...theme.typography.Body_medium_14,
}));

const ArrowDown = styled(ArrowDownSvg)(({ theme }) => ({
  width: 20,
  height: 20,
  cursor: "pointer",
  path: {
    fill: "#000",
  },
}));

// ----------------------------------------------------------------------

export default function MyAccount() {
  let navigate = useHistory();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [userData] = useState();
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // }, []);
  const handleClick = () => {
    navigate("/change-password", { state: userData });
  };
  const handleOut = () => {
    // removeUserData("userData");
    navigate("/login");
  };
  return (
    <>
      <Box
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          cursor: "pointer",
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              position: "absolute",
            },
          }),
        }}
      >
        <Stack direction="row" px={0} spacing={2.25} alignItems="center">
          <User />

          <Typography variant="Body_medium_14">User Name</Typography>

          <ArrowDown />
        </Stack>
      </Box>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <MenuItemStyled>
          <MenuItemWrapper
            // component={RouterLink}
            // to="/change-password"
            onClick={handleClick}
            sx={{ textDecoration: "none" }}
          >
            <Box>
              <PasswordIcon
                sx={{ color: (theme) => theme.palette.grey.main }}
              />
            </Box>
            <Typography color="common.black">Change Password</Typography>
          </MenuItemWrapper>
        </MenuItemStyled>

        <MenuItemStyled>
          <MenuItemWrapper
            // component={RouterLink}
            // to="/login"
            onClick={handleOut}
            sx={{ textDecoration: "none" }}
          >
            <Box>
              <SignOut />
            </Box>
            <Typography color="common.black">Sign out</Typography>
          </MenuItemWrapper>
        </MenuItemStyled>
      </MenuPopover>
    </>
  );
}
