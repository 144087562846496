import React from 'react';

import { Bar } from 'react-chartjs-2';

const Apaexlinecolumn = (props) => {

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Chart.js Bar Chart',
      },
    },
  };








  function getChartData(rawData) {
    if (!rawData) {
      return false
    }
    var monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const months = Object.keys(rawData.MonthlyTransactions);
    const monthLabels = months.map(m => {
      return monthNames[m.split("-")[0]];
    });
    const monthExpenseValues = months.map(m => {
      let transactions = rawData.MonthlyTransactions[m].filter(f => f.Expense === true).map(t => parseInt(t.Amount.Amount));
      if (transactions && transactions.length > 0)
        return transactions.reduce((accumulator, curr) => accumulator + curr);
      else
        return 0;
    });
    const monthIncomeValues = months.map(m => {
      let transactions = rawData.MonthlyTransactions[m].filter(f => f.Income === true).map(t => parseInt(t.Amount.Amount));
      if (transactions && transactions.length > 0)
        return transactions.reduce((accumulator, curr) => accumulator + curr);
      else
        return 0;
    });

    const data = {
      labels: monthLabels,
      datasets: [
        {
          label: 'Expense',
          data: monthExpenseValues,
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: 'Income',
          data: monthIncomeValues,
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    };
    return data;
  }


  var response = getChartData(props?.detailData);


  const data = response

  return (
    <Bar options={options} data={data} />
  )
}

export default Apaexlinecolumn
