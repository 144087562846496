import PropTypes from 'prop-types';
// material-ui
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from 'react';
import { getCustomer } from '../../../helpers/backend_helper';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

// table header
const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: '',
    numeric: true,
    disablePadding: false,
    width: '0.5px',
    label: '',
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  border: '1px solid #DEDEDE',
  color: '#212120',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '500',
}));

const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#F0F0F0',
  color: 'rgba(33, 33, 32, 0.60)',
  border: '1px solid #DEDEDE',
}));

// ==============================|| TABLE - HEADER ||============================== //

function EnhancedTableHead({ order, orderBy, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCellHead
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCellHead>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

// ==============================|| TABLE - TOOLBAR ||============================== //

const EnhancedTableToolbar = ({ numSelected }) => (
  <Toolbar
    sx={{
      p: 0,
      pl: 1,
      pr: 1,
      ...(numSelected > 0 && {
        color: (theme) => theme.palette.secondary.main,
      }),
    }}
  >
    <Box sx={{ flexGrow: 1 }} />
    {numSelected > 0 && (
      <Tooltip title="Delete">
        <IconButton size="large">
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    )}
  </Toolbar>
);

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

// ==============================|| TABLE - ENHANCED ||============================== //

export default function Payments() {
  const theme = useTheme();
  const history = useHistory();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense] = useState(false);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [customerList, setCustomerList] = useState([]);

  const fetchCustomerList = async () => {
    const payload = {
      page: page + 1,
      limit: limit,
      sortBy: orderBy,
      sortDirection: order,
      search: '',
    };
    await getCustomer(payload)
      .then((res) => {
        setCustomerList(res.data.items || []);
        setTotal(res.data.total);
      })
      .catch((err) => {
        toast.error('Something went wrong!');
      });
  };

  useEffect(() => {
    fetchCustomerList();
  }, [limit, page, order, orderBy]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (row) => {
    history.push(`/direct-debit/${row?.userName}`, { state: row });
  };

  return (
    <Box mt={1}>
      <Card sx={{ mt: 2 }}>
        <TableContainer>
          <Table
            sx={{
              minWidth: 750,
              backgroundColor: 'white',
            }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={total}
            />
            <TableBody>
              {customerList.length ? (
                customerList.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.name}
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleRowClick(row)}
                    >
                      <StyledTableCell component="th" id={labelId} scope="row">
                        {row.userName}
                      </StyledTableCell>
                      <StyledTableCell>{row.userEmail}</StyledTableCell>
                      <StyledTableCell>
                        <MoreVertIcon />
                      </StyledTableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* table pagination */}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={total}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Box>
  );
}
