import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";

// Redux
import { Link, withRouter } from "react-router-dom";

// availity-reactstrap-validation
import {
  AvField,
  AvForm
} from "availity-reactstrap-validation";

import { postLogin } from '../../helpers/backend_helper';
// import images
import logoActive from '../../assets/images/logo-active.png';
//Import config

const Login = (props) => {
  const [userType, setUserType] = useState('primary');
  const [error, setError] = useState();
  const [toggle, setToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    let user = {
      email: values.email,
      password: values.password,
    };
    // if (userType === 'secondary') {
    //   user['alias'] = values.alias;
    // }
    setLoading(true);
    postLogin(values)
      .then((response) => {
        if (response.codes === 200) {
          response.data['userType'] =
            response.data.userType !== undefined ? response.data.userType : 'P';
          localStorage.setItem('authUser', JSON.stringify(response));
          props.history.push('/client-list');
          setLoading(false);
        } else {
          setError(response.message);
          setLoading(false);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    document.body.className = 'authentication-bg';
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = '';
    };
  });

  const hanldeUserType = (e) => {
    setUserType(e.target.value);
  };
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody className="p-4">
                  <Row>
                    <Col lg={12}>
                      <div className="text-center">
                        <Link to="/" className="my-2 d-block auth-logo">
                          <img
                            src={logoActive}
                            alt=""
                            height="50"
                            className="logo logo-dark"
                          />
                        </Link>
                      </div>
                    </Col>
                  </Row>
                  <div className="p-2 mt-4">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      {error && typeof error === 'string' ? (
                        <Alert color="danger">{error}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3 position-relative">
                        <div className="float-end">
                          <Link to="/forgot-password" className="text-muted">
                            Forgot password?
                          </Link>
                        </div>

                        <AvField
                          name="password"
                          label="Password"
                          type={toggle ? 'text' : 'password'}
                          required
                          placeholder="Enter Password"
                        />
                        {toggle ? (
                          <i
                            className="uil uil-eye eye-icon"
                            onClick={() => setToggle(!toggle)}
                          ></i>
                        ) : (
                          <i
                            className="uil uil-eye-slash eye-icon"
                            onClick={() => setToggle(!toggle)}
                          ></i>
                        )}
                      </div>
                      {/* <div className="mb-3">
                        <h5 className="font-size-14">User Type</h5>
                        <div className="form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="userType"
                            id="userTypePrimary"
                            value="primary"
                            defaultChecked
                            onChange={hanldeUserType}
                          />
                          <label
                            className="form-check-label ml-2"
                            htmlFor="userTypePrimary"
                          >
                            Primary
                          </label>
                        </div>
                        <div className="form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="userType"
                            id="userTypeSecondary"
                            value="secondary"
                            onChange={hanldeUserType}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="userTypeSecondary"
                          >
                            Secondary
                          </label>
                        </div>
                      </div> */}
                      {/* {userType === 'secondary' && (
                        <div className="mb-3">
                          <AvField
                            name="alias"
                            label="Alias"
                            className="form-control"
                            placeholder="Enter alias"
                            type="text"
                            required={userType === 'secondary' ? true : false}
                          />
                        </div>
                      )} */}
                      <div className="mt-3">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? (
                            <span>
                              <Spinner
                                className="spinner-border-sm mr-5"
                                color="white"
                              />{' '}
                              Loading...
                            </span>
                          ) : (
                            'Log In'
                          )}
                        </button>
                      </div>
                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          I don't have an account ?{' '}
                          <a
                            href="/register"
                            className="fw-medium text-primary"
                          >
                            {' '}
                            Create Account{' '}
                          </a>{' '}
                        </p>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  // socialLogin: PropTypes.func
};
