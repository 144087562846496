import React, { useEffect, useState } from 'react';
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from 'reactstrap';

// Redux
import { useParams, withRouter } from 'react-router-dom';

// availity-reactstrap-validation
import { AvField, AvForm } from 'availity-reactstrap-validation';

// import images
import { postUpdatePassword } from '../../helpers/backend_helper';

const UpdatePassword = (props) => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [forgetError, setForgetError] = useState();
  const [forgetSuccessMsg, setForgetSuccessMsg] = useState();

  useEffect(() => {
    document.body.className = 'authentication-bg';
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = '';
    };
  });

  function handleValidSubmit(event, values) {
    setLoading(true);
    delete values.password1;
    values['token'] = params.token;
    // props.userForgetPassword(values, props.history)
    postUpdatePassword(values)
      .then((res) => {
        if (res === true) {
          setForgetSuccessMsg('Password changed Successfully');
          setLoading(false);
        }
        if (res.codes === 400) {
          setForgetError(res.message);
          setLoading(false);
        }
        if (res.codes === 401) {
          setForgetError(res.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5  pt-sm-5">
        <Container>
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div>
                <Card>
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Change Password</h5>
                      {/* <p className="text-muted">Create new password to proceed.</p> */}
                    </div>
                    <div className="p-2 mt-4">
                      {forgetError && forgetError ? (
                        <Alert
                          color="danger"
                          className="text-center mb-4"
                          style={{ marginTop: '13px' }}
                        >
                          {forgetError}
                        </Alert>
                      ) : null}
                      {forgetSuccessMsg ? (
                        <Alert
                          color="success"
                          className="text-center mb-4"
                          style={{ marginTop: '13px' }}
                        >
                          {forgetSuccessMsg}
                        </Alert>
                      ) : null}

                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                      >
                        <div className="col-12 mb-3">
                          <AvField
                            name="oldPassword"
                            type="text"
                            label="Existing Password"
                            placeholder="Existing Password"
                            errorMessage="Enter existing password"
                            validate={{ required: { value: true } }}
                          />
                        </div>
                        <div className="col-12 mb-3">
                          <AvField
                            name="newPassword"
                            type="password"
                            label="Password"
                            placeholder="Password"
                            errorMessage="Enter password"
                            validate={{ required: { value: true } }}
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="password1"
                            type="password"
                            label="Confirm Password"
                            placeholder="Confirm Password"
                            errorMessage="Password and confirm passowrd must be same"
                            validate={{
                              required: { value: true },
                              match: { value: 'newPassword' },
                            }}
                          />
                        </div>
                        <Row className="row mb-0">
                          <Col className="col-12 text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              {loading ? (
                                <span>
                                  <Spinner
                                    className="spinner-border-sm mr-5"
                                    color="white"
                                  />{' '}
                                  Loading...
                                </span>
                              ) : (
                                'Change Password'
                              )}
                            </button>
                          </Col>
                        </Row>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

UpdatePassword.propTypes = {};

export default withRouter(UpdatePassword);
