import {
  Autocomplete,
  Box,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import Loader from '../../components/Common/Loader';
import {
  BoxWrapper,
  MainStyle,
  RootStyle,
} from '../../components/Common/Style';
import { getUserData } from '../../components/Common/common';
import DashboardUINav from "../../components/Navbar/DashboardUINav/DashboardUINav";
import {
  getConsent,
  getDashboard,
  getRisk,
} from '../../helpers/backend_helper';
import {
  AmountText,
  RAICard,
  RAICardInfoBox,
  SecondaryCard,
  StyledTableCellData,
  StyledTableCellHead,
  StyledTableContainer,
  StyledTableRow,
  TableCard,
} from './RiskAndInsightStyle';

const monthRange = [
  {
    title: "3 Months",
    value: 3,
  },
  {
    title: "6 Months",
    value: 6,
  },
  {
    title: "9 Months",
    value: 9,
  },
  {
    title: "12 Months",
    value: 12,
  },
];
const typeOptions = [
  {
    title: "Total",
    value: "tran_amount_total_for_period",
  },
  {
    title: "Monthly Average",
    value: "tran_amount_monthly_average",
  },
];


const RiskAndInsight = () => {
  const location = useLocation();
  const history = useHistory();
  let [data, setData] = useState();
  const [totalOutFlow, setTotalOutFlow] = useState();
  let [noSalaryMonths, setNoSalaryMonths] = useState();
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [consent, setConsent] = useState();
  const [selectAccount, setSelectAccount] = useState();
  const [selectMonth, setSelectMonth] = useState();
  const [filletElement, setFilletElement] = useState([]);
  const [loading, setLoading] = useState();
  const [selectedDateRang, setSelectedDateRang] = useState([]);
  const [endDate, setEndDate] = useState();
  const [accountsNumberList, setAccountsNumberList] = useState([]);
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [selectedType, setSelectedType] = useState(
    "tran_amount_total_for_period"
  );
  const [totalAffordability, setTotalAffordability] = useState([]);
  const [totalInFlow, setTotalInFlow] = useState();

  const [totalExpense, setTotalExpense] = useState([]);
  const [totalIncome, setTotalIncome] = useState([]);
  const [grantInflowTotal, setGrantInflowTotal] = useState();
  const [grantOutFlowTotal, setGrantOutFlowTotal] = useState();
  const [dashboardData, setDashboardData] = useState();
  const [riskResponse, setRiskResponse] = useState({});
  const [grantTotal, setGrantTotal] = useState();
  const [riskIndicators, setRiskIndicators] = useState();
  const [selectedConsent, setSelectedConsent] = useState({});
  const [selectedAccountType, setSelectedAccountType] = useState(null);

  useEffect(() => {
    const maxObject =
      consent &&
      consent.length &&
      consent.reduce((max, currentObject) => {
        if (
          currentObject.id > max.id &&
          currentObject?.dashboardAvailable &&
          currentObject?.consent?.data?.products.filter(
            (x) => x.code === 'affordability_full'
          ).length
        ) {
          return currentObject;
        }
        return max;
      }, consent[0]);
    if (maxObject && selectAccount !== 'all' && !selectAccount) {
      setSelectAccount(maxObject?.accountNumber);
      setSelectedAccountType(maxObject?.accountType);
    }
  }, [consent]);
  useEffect(() => {
    let noSalaryMonths = '';
    riskResponse?.noincome?.length &&
      riskResponse?.noincome.forEach((item, index) => {
        let separateBy = '';
        if (index === 0) {
          separateBy = '';
        } else if (index === data?.noincome.length - 1) {
          separateBy = ' and ';
        } else {
          separateBy = ', ';
        }
        noSalaryMonths =
          noSalaryMonths + separateBy + moment(item.tmonth).format('MMMM');
      });
    setNoSalaryMonths(noSalaryMonths);
  }, [riskResponse]);

  useEffect(async () => {
    let userData = getUserData();
    let endDate = moment().format('YYYY-MM-DD');
    setLoading(true);
    await getConsent(location)
      .then((res) => {
        if (res.codes == 200) {
          if (res.data?.length) {
            let accountNumberList = [];
            res?.data.map((v) => {
              if (
                v.consent.data.products.filter(
                  (item) =>
                    item.code === 'affordability_basic' ||
                    item.code === 'affordability_full'
                ).length
              )
                if (v.accountNumber) {
                  accountNumberList.push({
                    title: `XXXXXX${v.accountNumber.slice(-4)}`,
                    value: v.accountNumber,
                  });
                }
            });
            if (!accountNumberList.length) {
              navigator('/dashboard-overview');
            }
            accountNumberList.push({
              title: 'CONSOLIDATED',
              value: 'all',
              icon: '',
              bankName: '',
            });
            setAccountsNumberList(accountNumberList);

            let selectedProduct = res?.data?.find(
              (item) => item.accountNumber === selectAccount
            );

            if (selectedProduct && selectAccount?.length) {
              endDate = moment(selectedProduct?.consent?.data?.dateTo).format(
                'YYYY-MM-DD'
              );
              let dateRangArray = [
                {
                  startDate: selectedProduct?.consent?.data?.dateFrom,
                  endDate: selectedProduct?.consent?.data?.dateTo,
                },
              ];
              calculateDateRang(dateRangArray, setSelectedDateRang);
              setSelectedConsent(selectedProduct);
            } else {
              let dateRangArray = res?.data.map((v) => {
                return {
                  startDate: v.consent?.data?.dateFrom,
                  endDate: v.consent?.data?.dateTo,
                };
              });
              let allEndDate = res?.data.map((v) => v.consent?.data?.dateTo);
              let maxEndDate =
                allEndDate.length &&
                allEndDate.reduce(function (a, b) {
                  return a > b ? a : b;
                });

              calculateDateRang(dateRangArray, setSelectedDateRang);
              endDate = moment(maxEndDate).format('YYYY-MM-DD');
            }
            setEndDate(endDate);
            
            setConsent(res?.data);
          }
        } else if (res?.codes === 404) {
          history.push('/login');
        } else {
          setLoading(false);
          toast.error(
            res?.response?.data?.message ||
              res.message ||
              'Something went wrong!'
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error('Something went wrong!');
      });
  }, [selectMonth, selectAccount, selectedEndDate]);
  useEffect(async () => {
    let dataArray = [];
    let data = selectedDateRang.length;
    monthRange.forEach((v, index) => {
      if (data >= v.value) {
        dataArray.push(v);
      }
    });
    setFilletElement(dataArray);
    let dateToValue = selectMonth
      ? [...filletElement, ...selectedDateRang].find((item) => {
          if (selectedEndDate) {
            return item.value === selectedEndDate;
          } else {
            return item.value === selectMonth;
          }
        })
      : filletElement[filletElement.length - 1];
    let payload = {
      user_id: location?.state?.client,
      date_to:
        moment(dateToValue?.title).format('MMMM - YYYY') !== 'Invalid date'
          ? dateToValue?.value
          : endDate,
      n_months:
        moment(dateToValue?.title).format('MMMM - YYYY') !== 'Invalid date'
          ? 1
          : selectMonth || dataArray[dataArray.length - 1].value,
      account_id: selectAccount,
      tran_threshold: 0,
    };
    if (payload.user_id && payload.date_to && payload.n_months) {
      await getRisk(payload).then((res) => {
        if (res?.codes === 200) {
          setLoader(false);
          const result = res?.data?.transaction_threshold.reduce(
            (total, thing) => total + thing[selectedType],
            0
          );
          setGrantTotal(result);
          setRiskResponse(res?.data);
        } else {
          setRiskResponse([]);
          setGrantTotal();
          setLoader(false);
          toast.error(res?.response?.data?.message || res.message);
        }
      });
    }

    let dashboardPayload = {
      userId: location?.state?.client,
      date_to:
        moment(dateToValue?.title).format('MMMM - YYYY') !== 'Invalid date'
          ? dateToValue?.value
          : endDate,
      n_months:
        moment(dateToValue?.title).format('MMMM - YYYY') !== 'Invalid date'
          ? 1
          : selectMonth || dataArray[dataArray.length - 1].value,
      account_id: selectAccount,
      accountType: selectedAccountType || selectedConsent?.accountType,
    };

    if (
      selectAccount &&
      dashboardPayload?.userId &&
      dashboardPayload?.date_to &&
      dashboardPayload?.n_months
    ) {
      getDashboard(dashboardPayload).then((res) => {
        if (res?.codes === 200) {
          setLoading(false);
          // if (res.data?.data?.data.length) {
          let getIncome = res.data?.data.filter((item) => {
            return (
              item.aggregation_lvl === 1 &&
              item.tran_type === 'I' &&
              item.category_lvl1 === 'Income'
            );
          });

          let getExpense = res?.data?.data.filter((item) => {
            return (
              item.aggregation_lvl === 1 &&
              item.tran_type === 'E' &&
              item.category_lvl1 === 'Expense'
            );
          });
          let getAffordability = res?.data?.data.filter((item) => {
            return (
              item.aggregation_lvl === 1 &&
              item.tran_type === null &&
              item.category_lvl1 === 'Affordability'
            );
          });
          let getInFlow = res?.data?.data.filter((item) => {
            return (
              item.aggregation_lvl == 1 &&
              item.tran_type === 'I' &&
              item.category_lvl1 != 'Inflow Reversals' &&
              item.category_lvl2 && [selectedType] &&
              [selectedType] !== 0
            );
          });
          if (getInFlow.length) {
            const result = getInFlow.reduce(
              (total, thing) => total + thing[selectedType],
              0
            );
            setGrantInflowTotal(result);
          } else {
            setGrantInflowTotal(0);
          }
          let getOutFlow = res?.data?.data.filter((item) => {
            return (
              item.aggregation_lvl === 1 &&
              item.tran_type === 'E' &&
              item.category_lvl2 && [selectedType] &&
              [selectedType] !== 0
            );
          });
          if (getOutFlow.length) {
            const result = getOutFlow.reduce(
              (total, thing) => total + thing[selectedType],
              0
            );
            setGrantOutFlowTotal(result);
          } else {
            setGrantOutFlowTotal(0);
          }
          let getRiskIndicators = res?.data?.data.filter((item) => {
            return (
              item.is_risky === 1 &&
              item.category_lvl2 &&
              item.category_lvl1 != 'Inflow Reversals'
            );
          });

          setTotalIncome(getIncome);
          setTotalExpense(getExpense);
          setTotalAffordability(getAffordability);
          setTotalInFlow(getInFlow);
          setTotalOutFlow(getOutFlow);
          setRiskIndicators(getRiskIndicators);
          // }
          setDashboardData(res?.data?.monthly);
          setLoading(false);
        } else if (res?.codes === 404) {
          history.push('/login');
        } else {
          setTotalIncome([]);
          setTotalExpense([]);
          setTotalAffordability([]);
          setTotalInFlow([]);
          setTotalOutFlow([]);
          setRiskIndicators([]);
          setDashboardData(res?.data?.monthly);
          setLoading(false);
          toast.error(
            res?.response?.data?.message ||
              res.message ||
              'Something went wrong!'
          );
        }
      });
    }
  }, [selectedDateRang, endDate]);
  return (
    <RootStyle>
      <DashboardUINav
        location={location}
        dashboardData={dashboardData}
        consent={consent}
      />
      <MainStyle mt={5}>
        {loading ? (
          <Loader />
        ) : (
          <BoxWrapper>
            <Stack
              direction="row"
              flexWrap="wrap"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mt: { lg: 5, xs: 15 } }}
            >
              <Box sx={{ flexGrow: 1 }} />

              <Box sx={{ flexGrow: 2, mt: { sm: 0, xs: 5 } }}>
                <Grid container gap={2} justifyContent="flex-end">
                  <Grid item md={2.5} xs={12}>
                    <Typography variant="Body_medium_16" color="orange.main">
                      Account Number
                    </Typography>
                    <Autocomplete
                      id="select-business-partner"
                      options={accountsNumberList}
                      getOptionLabel={(option) => option?.title}
                      // PaperComponent={CustomPaper}
                      value={accountsNumberList?.find(
                        (item) => item.value === selectAccount
                      )}
                      renderInput={(params) => (
                        <TextField
                          variant="filled"
                          id="filled-hidden-label-normal"
                          placeholder="Select..."
                          inputProps={{ "aria-label": "Without label" }}
                          hiddenLabel
                          fullWidth
                          {...params}
                        />
                      )}
                      onChange={(e, value) => {
                        setSelectAccount(value?.value || null);
                      }}
                    />
                  </Grid>

                  <Grid item md={2.5} xs={12}>
                    <Typography variant="Body_medium_16" color="orange.main">
                      Type
                    </Typography>
                    <Autocomplete
                      id="select-business-partner"
                      options={typeOptions}
                      getOptionLabel={(option) => option.title}
                      // PaperComponent={CustomPaper}
                      value={typeOptions.find(
                        (item) => item.value === selectedType
                      )}
                      renderInput={(params) => (
                        <TextField
                          variant="filled"
                          id="filled-hidden-label-normal"
                          placeholder="Select..."
                          inputProps={{ "aria-label": "Without label" }}
                          hiddenLabel
                          fullWidth
                          {...params}
                        />
                      )}
                      onChange={(e, value) => {
                        setSelectedType(value.value);
                      }}
                    />
                  </Grid>

                  {/* <Grid item md={2.5} xs={12}>
                    <Typography variant="Body_medium_16" color="orange.main">
                      Month
                    </Typography>
                    <Autocomplete
                      id="select-business-partner"
                      options={selectedDateRang}
                      getOptionLabel={(option) =>
                        moment(option).format("MMMM - YYYY")
                      }
                      // PaperComponent={CustomPaper}
                      value={selectedDateRang.find(
                        (item) => item === selectedEndDate
                      )}
                      renderInput={(params) => (
                        <TextField
                          variant="filled"
                          id="filled-hidden-label-normal"
                          placeholder="Select..."
                          inputProps={{ "aria-label": "Without label" }}
                          hiddenLabel
                          fullWidth
                          {...params}
                        />
                      )}
                      onChange={(e, newDate) => {
                        setSelectedEndDate(
                          newDate ? moment(newDate).format("YYYY-MM-DD") : ""
                        );
                      }}
                    />
                  </Grid> */}

                  <Grid item md={2.5} xs={12}>
                    <Typography variant="Body_medium_16" color="orange.main">
                      Filter Period
                    </Typography>
                    <Autocomplete
                      id="select-business-partner"
                      options={[...filletElement, ...selectedDateRang]}
                      getOptionLabel={(option) => {
                        if (
                          moment(option?.title).format("MMMM - YYYY") !==
                          "Invalid date"
                        ) {
                          return moment(option?.title).format("MMMM - YYYY");
                        } else {
                          return option?.title;
                        }
                      }}
                      value={
                        selectMonth
                          ? [...filletElement, ...selectedDateRang].find(
                              (item) => {
                                if (selectedEndDate) {
                                  return item.value === selectedEndDate;
                                } else {
                                  return item.value === selectMonth;
                                }
                              }
                            )
                          : filletElement[filletElement.length - 1]
                      }
                      renderInput={(params) => (
                        <TextField
                          variant="filled"
                          id="filled-hidden-label-normal"
                          placeholder="Select..."
                          inputProps={{ "aria-label": "Without label" }}
                          hiddenLabel
                          fullWidth
                          {...params}
                        />
                      )}
                      onChange={(e, newData) => {
                        setSelectMonth(newData?.value);
                        setSelectedEndDate(newData?.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Stack>

            <Box sx={{ flexGrow: 1 }} mt={5}>
              <Grid container>
                <Grid item md={5} sm={12} xs={12}>
                  <TableCard>
                    <StyledTableContainer>
                      <Table
                        sx={{
                          minWidth: 350,
                        }}
                      >
                        <TableHead>
                          <StyledTableRow>
                            <StyledTableCellHead>Item</StyledTableCellHead>
                            <StyledTableCellHead>
                              Percentage
                            </StyledTableCellHead>
                            <StyledTableCellHead>Amount</StyledTableCellHead>
                          </StyledTableRow>
                        </TableHead>
                        <TableBody>
                          {riskResponse?.transaction_threshold?.map((row) => (
                            <TableRow hover key={row.Item}>
                              <StyledTableCellData>
                                {row.category_lvl2}
                              </StyledTableCellData>
                              <StyledTableCellData>
                                {parseFloat(
                                  row.tran_amount_percentage_of_income * 100
                                ).toFixed(2)}
                                %
                              </StyledTableCellData>
                              <StyledTableCellData>
                                ₦{" "}
                                {(row[selectedType] || 0).toLocaleString(
                                  undefined,
                                  {
                                    maximumFractionDigits: 2,
                                  }
                                )}{" "}
                              </StyledTableCellData>
                            </TableRow>
                          ))}
                          <TableRow hover>
                            <StyledTableCellData>Total</StyledTableCellData>
                            <StyledTableCellData></StyledTableCellData>
                            <StyledTableCellData>
                              ₦{" "}
                              {(grantTotal || 0).toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                              })}{" "}
                            </StyledTableCellData>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                  </TableCard>
                </Grid>

                <Grid container item md={7} sm={12} xs={12}>
                  <Grid item md={6} sm={6} xs={12}>
                    <RAICard>
                      <Stack
                        direction="row"
                        alignItems="center"
                        mb={3}
                        spacing={1}
                      >
                        <Typography variant="Body_medium_16">
                          Income changes over period
                          {/* {riskResponse?.incomerisk?.movement} */}
                        </Typography>
                      </Stack>

                      <RAICardInfoBox>
                        <Typography variant="Body_light_14">
                          Salary payment {riskResponse?.incomerisk?.movement}{" "}
                          from
                        </Typography>
                        <AmountText>
                          ₦{" "}
                          {(
                            riskResponse?.incomerisk?.oldvalue || 0
                          ).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}{" "}
                        </AmountText>
                        <Typography variant="Body_light_14">
                        {selectedType === 'tran_amount_monthly_average' ? 'Monthly Average' : 'Total'}
                        </Typography>
                      </RAICardInfoBox>

                      <Stack direction="row" alignItems="center">
                        <Typography variant="" color="orange.main">
                          To
                        </Typography>
                        <Divider />
                      </Stack>

                      <RAICardInfoBox>
                        <AmountText>
                          ₦{" "}
                          {(
                            riskResponse?.incomerisk?.newvalue || 0
                          ).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}{" "}
                        </AmountText>
                        <Typography variant="Body_light_14">
                        {selectedType === 'tran_amount_monthly_average' ? 'Monthly Average' : 'Total'}
                        </Typography>
                      </RAICardInfoBox>
                    </RAICard>
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <RAICard>
                      <Stack
                        direction="row"
                        alignItems="center"
                        mb={3}
                        spacing={1}
                      >
                        <Typography variant="Body_medium_16">
                          Largest Funds Inflow/Outflow
                        </Typography>
                      </Stack>

                      <RAICardInfoBox>
                        <Typography variant="Body_light_14">
                          The largest Inflow transaction over period
                        </Typography>
                        <AmountText>
                          ₦{" "}
                          {(
                            riskResponse?.top_transactions?.inflow_top5?.[0]
                              ?.tran_amount || 0
                          ).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}{" "}
                        </AmountText>
                      </RAICardInfoBox>

                      <RAICardInfoBox>
                        <Typography variant="Body_light_14">
                          The largest Outflow transaction over period
                        </Typography>
                        <AmountText>
                          {" "}
                          ₦{" "}
                          {(
                            riskResponse?.top_transactions?.outflow_top5?.[0]
                              ?.tran_amount || 0
                          ).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}{" "}
                        </AmountText>
                      </RAICardInfoBox>
                    </RAICard>
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <SecondaryCard>
                      <Stack
                        alignItems="flex-start"
                        direction="column"
                        justifyContent="space-between"
                        gap={2}
                      >
                        <Typography variant="Body_medium_16">
                          Salary payments not consistent
                        </Typography>

                        <Stack direction="column">
                          <Typography variant="Body_light_14">
                            no salary payment /
                          </Typography>
                          <Typography variant="Body_light_14">
                            inflow in {noSalaryMonths}
                            {/* {noSalaryMonths} */}
                          </Typography>
                        </Stack>
                      </Stack>
                    </SecondaryCard>
                  </Grid>
                  {/* <Grid item md={8} sm={6} xs={12}>
                  <SecondaryCard>
                    <DirectDebitWrapper>
                      <Typography
                        variant="Body_semibold_18"
                        color="orange.main"
                      >
                        Direct Debit Mandates
                      </Typography>

                      <Stack
                        direction="column"
                        alignItems="end"
                        flexWrap="wrap"
                      >
                        <Typography variant="Body_light_14">
                          High monthly direct debits of
                        </Typography>
                        <Typography variant="Body_semibold_32">
                          ₦ 200,000.00
                        </Typography>

                        <Stack
                          direction="row"
                          gap={2}
                          mt={2}
                          alignItems="center"
                          flexWrap="wrap"
                        >
                          <Typography
                            variant="Body_medium_14"
                            color="orange.main"
                          >
                            via
                          </Typography>
                          <Box component="img" src={Paystack} alt="..." />
                          <Box component="img" src={Flutterwave} alt="..." />
                        </Stack>
                      </Stack>
                    </DirectDebitWrapper>
                  </SecondaryCard>
                </Grid> */}
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                opacity: "0.5",
              }}
            >
              Data Fetch from{" "}
              {moment(selectedEndDate ? selectedEndDate : endDate)
                .subtract(
                  selectedEndDate
                    ? 1
                    : selectMonth ||
                        filletElement[filletElement.length - 1]?.value,
                  "Month"
                )
                .format("DD/MM/YYYY")}{" "}
              to{" "}
              {moment(selectedEndDate ? selectedEndDate : endDate).format(
                "DD/MM/YYYY"
              )}
            </Box>
          </BoxWrapper>
        )}
      </MainStyle>
    </RootStyle>
  );
};
function calculateDateRang(allDateRange, setSelectedDateRang) {
  var result = [];
  allDateRange.forEach((v) => {
    var startDate = moment(v.startDate);
    var endDate = moment(v.endDate);
    if (endDate.isBefore(startDate)) {
      return;
    }
    while (startDate.isBefore(endDate)) {
      let lastDate = moment(startDate).endOf("month").format("YYYY-MM-DD");

      let diff = moment(endDate).diff(moment(lastDate), "days");
      if (diff < 0) {
        lastDate = moment(endDate).format("YYYY-MM-DD");
      }

      result.push(lastDate);
      startDate.add(1, "month");
    }
    result
      .sort(
        (a, b) =>
          new moment(a).format("YYYYMMDD") - new moment(b).format("YYYYMMDD")
      )
      .reverse();
    function filterUniqueDates(data) {
      const lookup = new Set();
      return data.filter((date) => {
        const serialised = new Date(date).getTime();

        if (lookup.has(serialised)) {
          return false;
        } else {
          lookup.add(serialised);
          return true;
        }
      });
    }
    let uniqueDates = filterUniqueDates(result);
    uniqueDates =
      uniqueDates.length &&
      uniqueDates.map((i) => {
        return {
          title: i,
          value: i,
        };
      });

    setSelectedDateRang(uniqueDates);
  });
}
export default RiskAndInsight;
