import {
  Box,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import { toast } from 'react-toastify';

import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Loader from '../../components/Common/Loader';
import { MainStyle, RootStyle } from '../../components/Common/Style';
import StyledCircularProgress from '../../components/Common/StyledCircularProgress';
import { getUserData } from '../../components/Common/common';
import DashboardUINav from '../../components/Navbar/DashboardUINav/DashboardUINav';
import { getConsent, getKYC } from '../../helpers/backend_helper';
import {
  CircularProgressWrapperIaJ,
  CircularProgressWrapperMain,
  ListWrapper,
  MainCardStyled,
  PageWrapper,
  StyledTableCellData,
  TableCellHead,
  TableWrapper,
} from './proofOfFundsStyle';
import styles from './styles.module.css';
const typeOptions = [
  {
    title: 'Total',
    value: 'tran_amount_total_for_period',
  },
  {
    title: 'Monthly Average',
    value: 'tran_amount_monthly_average',
  },
];
const monthRange = [
  {
    title: '3 Months',
    value: 3,
  },
  {
    title: '6 Months',
    value: 6,
  },
  {
    title: '9 Months',
    value: 9,
  },
  {
    title: '12 Months',
    value: 12,
  },
];

const ProofOfFunds = () => {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const [dashboardData, setDashboardData] = useState();
  const [filletElement, setFilletElement] = useState([]);
  const [selectedDateRang, setSelectedDateRang] = useState([]);
  const [selectMonth, setSelectMonth] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [accountsNumberList, setAccountsNumberList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectAccount, setSelectAccount] = useState();
  const [endDate, setEndDate] = useState();
  const [lastTransactionData, setLastTransactionData] = useState();
  const [transactionInfo, setTransactionInfo] = useState();
  const [filterTransactionInfo, setFilterTransactionInfo] = useState([]);
  const [isCheck, setIsCheck] = useState({
    isSalary: false,
    isDividends: false,
    businessIncome: false,
    investments: false,
    others: false,
  });
  const [isVerification, setIsVerification] = useState({
    isAddressVerification: true,
    isBankingVerification: true,
    isCustomerVerification: true,
  });

  const [consent, setConsent] = useState();
  useEffect(async () => {
    let userData = getUserData();
    let endDate = moment().format('YYYY-MM-DD');
    setLoading(true);
    await getConsent(location)
      .then((res) => {
        if (res.codes == 200) {
          if (res.data?.length) {
            let accountNumberList = [];
            res?.data.map((v) => {
              if (
                v.consent.data.products.filter((item) => item.code === 'pof')
                  .length
              )
                if (v.accountNumber) {
                  accountNumberList.push({
                    title: `XXXXXX${v.accountNumber.slice(-4)}`,
                    value: v.accountNumber,
                  });
                }
            });
            // if (!accountNumberList.length) {
            //     history.push("/dashboard-overview", { ...location?.state });
            // }

            let selectedProduct = res?.data?.find(
              (item) => item.accountNumber === selectAccount
            );

            if (selectedProduct && selectAccount?.length) {
              endDate = moment(selectedProduct?.consent?.data?.dateTo).format(
                'YYYY-MM-DD'
              );
              let dateRangArray = [
                {
                  startDate: selectedProduct?.consent?.data?.dateFrom,
                  endDate: selectedProduct?.consent?.data?.dateTo,
                },
              ];
              calculateDateRang(dateRangArray, setSelectedDateRang);
            } else {
              let dateRangArray = res?.data.map((v) => {
                return {
                  startDate: v.consent?.data?.dateFrom,
                  endDate: v.consent?.data?.dateTo,
                };
              });
              let allEndDate = res?.data.map((v) => v.consent?.data?.dateTo);
              let maxEndDate =
                allEndDate.length &&
                allEndDate.reduce(function (a, b) {
                  return a > b ? a : b;
                });

              calculateDateRang(dateRangArray, setSelectedDateRang);
              endDate = moment(maxEndDate).format('YYYY-MM-DD');
            }
            setEndDate(endDate);
            setAccountsNumberList(accountNumberList);
            setConsent(res?.data);
          }
        } else if (res?.codes === 404) {
          history.push('/login');
        } else {
          setLoading(false);
          toast.error(
            res?.response?.data?.message ||
              res.message ||
              'Something went wrong!'
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error('Something went wrong!');
      });
  }, [selectMonth, selectAccount, selectedEndDate]);

  useEffect(async () => {
    setLoading(true);
    let dataArray = [];
    let data = selectedDateRang.length;
    monthRange.forEach((v, index) => {
      if (data >= v.value) {
        dataArray.push(v);
      }
    });
    let dateToValue = selectMonth
      ? [...filletElement, ...selectedDateRang].find((item) => {
          if (selectedEndDate) {
            return item.value === selectedEndDate;
          } else {
            return item.value === selectMonth;
          }
        })
      : filletElement[filletElement.length - 1];
    let payload = {
      userId: location?.state?.client,
      date_to: endDate,
      n_months: 12,
      account_id: 0,
    };
    setLastTransactionData(
      moment(dateToValue?.title).format('MMMM - YYYY') !== 'Invalid date'
        ? dateToValue?.value
        : endDate
    );
    if (payload.userId && payload.date_to && payload.n_months) {
      await getKYC(payload).then((res) => {
        if (res?.codes === 200) {
          setLoading(false);
          setTransactionInfo(res?.data?.kycDetails);
        }
      });
    }
    setFilletElement(dataArray);
  }, [selectedDateRang, endDate]);
  useEffect(() => {
    let obj = {
      isSalary: false,
      isDividends: false,
      businessIncome: false,
      investments: false,
      others: false,
    };
    transactionInfo?.data?.length &&
      transactionInfo?.data.forEach((item) => {
        if (item?.category?.match('Business Income')) {
          obj = { ...obj, businessIncome: true };
        } else if (item?.category?.match('Salary')) {
          obj = { ...obj, isSalary: true };
        } else if (item?.category?.match('Dividend')) {
          obj = { ...obj, isDividends: true };
        } else {
          obj = { ...obj, others: true };
        }
      });
    setFilterTransactionInfo(transactionInfo?.data);
    setIsCheck({ ...obj });
  }, [transactionInfo]);
  // useEffect(() => {
  //     let listTransition = []

  //     if (isCheck?.isSalary) {
  //         listTransition = [...listTransition, ...transactionInfo?.data.filter((i) => i.category === "Salary")]
  //     }
  //     if (isCheck?.businessIncome) {
  //         listTransition = [...listTransition, ...transactionInfo?.data.filter((i) => i.category === "Business Income")]

  //     }
  //     if (isCheck?.isDividends) {
  //         listTransition = [...listTransition, ...transactionInfo?.data.filter((i) => i.category === "Dividend")]
  //     }
  //     if (isCheck?.others) {
  //         listTransition = transactionInfo?.data.filter((i) =>
  //             i.category !== "Salary" &&
  //             i.category !== "Business Income" &&
  //             i.category !== "Dividend"
  //         )
  //         listTransition = [...listTransition, ...transactionInfo?.data.filter((i) => i.category !== "Business Income")]
  //     }
  //     setFilterTransactionInfo([...listTransition])
  // }, [isCheck])

  return (
    <RootStyle>
      <DashboardUINav
        location={location}
        dashboardData={dashboardData}
        consent={consent}
      />
      <MainStyle>
        {loading ? (
          <Loader />
        ) : (
          <PageWrapper>
            {/* <Grid item md={2.5} xs={12}>
                        <Typography variant="Body_medium_16" color="orange.main">
                            Filter Period
                        </Typography>
                        <Autocomplete
                            id="select-business-partner"
                            options={[...filletElement, ...selectedDateRang]}
                            getOptionLabel={(option) => {
                                if (
                                    moment(option?.title).format("MMMM - YYYY") !==
                                    "Invalid date"
                                ) {
                                    return moment(option?.title).format("MMMM - YYYY");
                                } else {
                                    return option?.title;
                                }
                            }}
                            value={
                                selectMonth
                                    ? [...filletElement, ...selectedDateRang].find(
                                        (item) => {
                                            if (selectedEndDate) {
                                                return item.value === selectedEndDate;
                                            } else {
                                                return item.value === selectMonth;
                                            }
                                        }
                                    )
                                    : filletElement[filletElement.length - 1]
                            }
                            renderInput={(params) => (
                                <TextField
                                    variant="filled"
                                    id="filled-hidden-label-normal"
                                    placeholder="Select..."
                                    inputProps={{ "aria-label": "Without label" }}
                                    hiddenLabel
                                    fullWidth
                                    {...params}
                                />
                            )}
                            onChange={(e, newData) => {
                                setSelectMonth(newData?.value);
                                setSelectedEndDate(newData?.value);
                            }}
                        />
                    </Grid> */}
            {/* <BoxWrapper>
                        <Stack
                            direction="row"
                            flexWrap="wrap"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ mt: { lg: 5, xs: 15 } }}
                        >
                            <Box sx={{ flexGrow: 1 }} />
                            <Box sx={{ flexGrow: 2, mt: { sm: 0, xs: 5 } }}>
                                <Grid container gap={2} justifyContent="flex-end">
                                    <Grid item md={2.5} xs={12}>
                                        <Typography variant="Body_medium_16" color="orange.main">
                                            Filter Period
                                        </Typography>
                                        <Autocomplete
                                            id="select-business-partner"
                                            options={[...filletElement, ...selectedDateRang]}
                                            getOptionLabel={(option) => {
                                                if (
                                                    moment(option?.title).format("MMMM - YYYY") !==
                                                    "Invalid date"
                                                ) {
                                                    return moment(option?.title).format("MMMM - YYYY");
                                                } else {
                                                    return option?.title;
                                                }
                                            }}
                                            value={
                                                selectMonth
                                                    ? [...filletElement, ...selectedDateRang].find(
                                                        (item) => {
                                                            if (selectedEndDate) {
                                                                return item.value === selectedEndDate;
                                                            } else {
                                                                return item.value === selectMonth;
                                                            }
                                                        }
                                                    )
                                                    : filletElement[filletElement.length - 1]
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    variant="filled"
                                                    id="filled-hidden-label-normal"
                                                    placeholder="Select..."
                                                    inputProps={{ "aria-label": "Without label" }}
                                                    hiddenLabel
                                                    fullWidth
                                                    {...params}
                                                />
                                            )}
                                            onChange={(e, newData) => {
                                                setSelectMonth(newData?.value);
                                                setSelectedEndDate(newData?.value);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Stack>
                    </BoxWrapper > */}
            <Grid container my={4} spacing={2}>
              <Grid item md={6} xs={12}>
                <MainCardStyled>
                  <Box>
                    <Typography variant="Body_medium_18">Rating</Typography>
                  </Box>

                  <CircularProgressWrapperMain my={3}>
                    <CircularProgressWrapperIaJ>
                      <StyledCircularProgress
                        progressValue={
                          transactionInfo?.last_trasaction_date !== 'nan'
                            ? moment(
                                transactionInfo?.last_trasaction_date
                              ).format('DD-MMM-YYYY')
                            : moment(lastTransactionData).format('DD-MMM-YYYY')
                        }
                        indicatorColor={theme.palette.orange.main}
                        fontSize="9px"
                      />
                      <Typography variant="Body_medium_14">
                        Last Transaction Date
                      </Typography>
                    </CircularProgressWrapperIaJ>

                    <CircularProgressWrapperIaJ>
                      <StyledCircularProgress
                        progressValue={transactionInfo?.n_income_source}
                        indicatorColor={theme.palette.success.main}
                      />
                      <Typography variant="Body_medium_14">
                        Source of Income
                      </Typography>
                    </CircularProgressWrapperIaJ>

                    <CircularProgressWrapperIaJ>
                      <StyledCircularProgress
                        progressValue={transactionInfo?.transactions_risk_level}
                        indicatorColor={`${
                          transactionInfo?.transactions_risk_level === 'High'
                            ? '#FF0000'
                            : '#40EA8F'
                        }`}
                      />
                      <Typography variant="Body_medium_14">
                        Transaction Risk Level Low, Medium, High
                      </Typography>
                    </CircularProgressWrapperIaJ>

                    <CircularProgressWrapperIaJ>
                      <StyledCircularProgress
                        progressValue={transactionInfo?.aml_flag}
                        indicatorColor={theme.palette.success.main}
                      />
                      <Typography variant="Body_medium_14">
                        AML Flags
                      </Typography>
                    </CircularProgressWrapperIaJ>

                    <CircularProgressWrapperIaJ>
                      <StyledCircularProgress
                        progressValue={transactionInfo?.n_transactions}
                        indicatorColor={theme.palette.success.main}
                      />
                      <Typography variant="Body_medium_14">
                        No. of Transaction
                      </Typography>
                    </CircularProgressWrapperIaJ>
                  </CircularProgressWrapperMain>
                </MainCardStyled>
              </Grid>
              <Grid item md={6} xs={12}>
                <MainCardStyled>
                  <Box>
                    <Typography variant="Body_medium_18">
                      Verification
                    </Typography>
                  </Box>

                  <Box>
                    <ListWrapper className={styles.list}>
                      <input
                        type="checkbox"
                        id="BankAccountVerification"
                        name="Bank account verification"
                        checked={isVerification?.isBankingVerification}
                        onChange={(e) => {
                          setIsVerification({
                            ...isVerification,
                            isBankingVerification: e.target.checked,
                          });
                        }}
                      />
                      <label htmlFor="BankAccountVerification">
                        Bank account verification
                      </label>
                    </ListWrapper>
                    <ListWrapper className={styles.list}>
                      <input
                        type="checkbox"
                        id="CustomerVerification"
                        name="Customer Verification"
                        checked={isVerification?.isCustomerVerification}
                        onChange={(e) => {
                          setIsVerification({
                            ...isVerification,
                            isCustomerVerification: e.target.checked,
                          });
                        }}
                      />
                      <label htmlFor="CustomerVerification">
                        Customer Verification
                      </label>
                    </ListWrapper>
                    <ListWrapper className={styles.list}>
                      <input
                        type="checkbox"
                        id="AddressVerification"
                        name="Address Verification"
                        checked={isVerification?.isAddressVerification}
                        onChange={(e) => {
                          setIsVerification({
                            ...isVerification,
                            isAddressVerification: e.target.checked,
                          });
                        }}
                      />
                      <label htmlFor="AddressVerification">
                        Address Verification
                      </label>
                    </ListWrapper>
                  </Box>

                  <Box mt={4} mb={3}>
                    <Typography variant="Body_medium_18">
                      Source of income
                    </Typography>
                  </Box>

                  <Box>
                    <ListWrapper className={styles.list}>
                      <input
                        type="checkbox"
                        id="Salary"
                        name="Salary"
                        // checked={isCheck?.isSalary}
                        onChange={(e) => {
                          setIsCheck({
                            ...isCheck,
                            isSalary: e.target.checked,
                          });
                        }}
                      />
                      <label htmlFor="Salary">Salary</label>
                    </ListWrapper>
                    <ListWrapper className={styles.list}>
                      <input
                        type="checkbox"
                        id="Dividends"
                        name="Dividends"
                        // checked={isCheck?.isDividends}
                        onChange={(e) => {
                          setIsCheck({
                            ...isCheck,
                            isDividends: e.target.checked,
                          });
                        }}
                      />
                      <label htmlFor="Dividends">Dividends</label>
                    </ListWrapper>
                    <ListWrapper className={styles.list}>
                      <input
                        type="checkbox"
                        id="BusinessIncome"
                        name="Business Income"
                        // checked={isCheck?.businessIncome}
                        onChange={(e) => {
                          setIsCheck({
                            ...isCheck,
                            businessIncome: e.target.checked,
                          });
                        }}
                      />
                      <label htmlFor="BusinessIncome">Business Income</label>
                    </ListWrapper>
                    <ListWrapper className={styles.list}>
                      <input
                        type="checkbox"
                        id="Investments"
                        name="Investments"
                        // checked={isCheck?.investments}
                        onChange={(e) => {
                          setIsCheck({
                            ...isCheck,
                            investments: e.target.checked,
                          });
                        }}
                      />
                      <label htmlFor="Investments">Investments</label>
                    </ListWrapper>
                    <ListWrapper className={styles.list}>
                      <input
                        type="checkbox"
                        id="Others"
                        name="Others"
                        // checked={isCheck?.others}
                        onChange={(e) => {
                          setIsCheck({ ...isCheck, others: e.target.checked });
                        }}
                      />
                      <label htmlFor="Others">Others</label>
                    </ListWrapper>
                  </Box>
                </MainCardStyled>
              </Grid>
              <Grid item xs={12}>
                <MainCardStyled>
                  <TableWrapper>
                    <Table
                      stickyHeader
                      sx={{
                        minWidth: 350,
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCellHead>Detail</TableCellHead>
                          <TableCellHead>Date</TableCellHead>
                          <TableCellHead>Description</TableCellHead>
                          <TableCellHead>Category</TableCellHead>
                          <TableCellHead>Amount</TableCellHead>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filterTransactionInfo?.length ? (
                          <>
                            {filterTransactionInfo.map((item, index) => (
                              <TableRow key={index} hover>
                                <StyledTableCellData>
                                  {item?.detail}
                                </StyledTableCellData>
                                <StyledTableCellData>
                                  {item?.description !== 'Total' &&
                                    moment(item?.date).format('DD MMM YYYY')}
                                </StyledTableCellData>
                                <StyledTableCellData>
                                  {item?.description}
                                </StyledTableCellData>
                                <StyledTableCellData>
                                  {item?.category}
                                </StyledTableCellData>
                                <StyledTableCellData>
                                  ₦ {item?.amount}
                                </StyledTableCellData>
                              </TableRow>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                      </TableBody>
                    </Table>
                  </TableWrapper>
                </MainCardStyled>
              </Grid>
            </Grid>
          </PageWrapper>
        )}
      </MainStyle>
    </RootStyle>
  );
};
function calculateDateRang(allDateRange, setSelectedDateRang) {
  var result = [];
  allDateRange.forEach((v) => {
    var startDate = moment(v.startDate);
    var endDate = moment(v.endDate);
    if (endDate.isBefore(startDate)) {
      return;
    }
    while (startDate.isBefore(endDate)) {
      let lastDate = moment(startDate).endOf('month').format('YYYY-MM-DD');

      let diff = moment(endDate).diff(moment(lastDate), 'days');
      if (diff < 0) {
        lastDate = moment(endDate).format('YYYY-MM-DD');
      }

      result.push(lastDate);
      startDate.add(1, 'month');
    }
    result
      .sort(
        (a, b) =>
          new moment(a).format('YYYYMMDD') - new moment(b).format('YYYYMMDD')
      )
      .reverse();
    function filterUniqueDates(data) {
      const lookup = new Set();
      return data.filter((date) => {
        const serialised = new Date(date).getTime();

        if (lookup.has(serialised)) {
          return false;
        } else {
          lookup.add(serialised);
          return true;
        }
      });
    }
    let uniqueDates = filterUniqueDates(result);
    uniqueDates =
      uniqueDates.length &&
      uniqueDates.map((i) => {
        return {
          title: i,
          value: i,
        };
      });

    setSelectedDateRang(uniqueDates);
  });
}

export default ProofOfFunds;
