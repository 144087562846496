import { styled } from "@mui/material/styles";
import { Box, TableCell, TableContainer, Typography } from "@mui/material";
import MainCard from "../../components/Common/MainCard";
//

export const OverviewWrapper = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2),

  [theme.breakpoints.up("lg")]: {
    margin: theme.spacing(5),
  },
}));

export const PageBoxWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2, 0, 2),

  [theme.breakpoints.up("lg")]: {
    padding: theme.spacing(0, 5, 0, 5),
  },
}));

export const TopCard = styled(MainCard)(({ theme }) => ({
  padding: theme.spacing(1),
  height: "auto",

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(2),
    height: "394px",
  },
}));

export const TopCardInfoBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3.75, 0, 3.25, 0),
  gap: theme.spacing(0.75),
  display: "flex",
  flexDirection: "column",
}));

export const TopCardListWrapper = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3.75, 0, 3.25, 0),
  gap: theme.spacing(0.75),
  display: "flex",
  flexDirection: "column",
}));

export const TopCardListItem = styled(Box)(({ theme }) => ({
  gap: theme.spacing(0.75),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const RiskCardInfoWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  width: "100%",
  justifyContent: "space-between",
  gap: theme.spacing(1.5),
  margin: theme.spacing(2, 1),

  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
  },
}));

export const AccBalanceDigit = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_light_10,
}));

export const AccBalanceDigitWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
}));

export const AccBalanceDigitBox = styled(Box)(({ theme }) => ({
  background: theme.palette.common.white,
  padding: "20px",
  borderRadius: theme.spacing(1.2),
  display: "flex",
  flexDirection: "column",
}));

export const CircularProgressWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  gap: 3,
}));

export const PageHead = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",

  [theme.breakpoints.up("md")]: {
    alignItems: "center",
    flexDirection: "row",
  },
}));

export const TableWrapper = styled(TableContainer)(({ theme }) => ({
  maxHeight: "auto",
  overflowX: "hidden",
  [theme.breakpoints.up("sm")]: {
    maxHeight: "340px",
  },
}));

export const TableCellHead = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey.dark}`,
  color: theme.palette.common.black,
  ...theme.typography.Body_medium_14,
  [theme.breakpoints.up("sm")]: {
    ...theme.typography.Body_medium_16,
  },
}));

export const TableCellDataItemName = styled(TableCell)(({ theme }) => ({
  // border: "none",
  borderBottom: `1px solid ${theme.palette.grey.dark}`,
  color: theme.palette.common.black,
  ...theme.typography.Body_light_14,
}));

export const TableCellDataAmount = styled(TableCell)(({ theme }) => ({
  // border: "none",
  borderBottom: `1px solid ${theme.palette.grey.dark}`,
  color: theme.palette.common.black,
  ...theme.typography.Body_medium_14,
}));
