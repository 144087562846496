import PropTypes from 'prop-types';
import React, { useEffect, useState , useNavigate} from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Alert, Col, Container, Row } from 'reactstrap';
import logoActive from '../../assets/images/logo-active.png';
import JwtService from '../../helpers/jwtService';

const VerifyUser = (props) => {
  const { token } = useParams();
  const [loader, setLoader] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [visible, setAlert] = useState(false);

  useEffect(() => {
    document.body.className = 'authentication-bg';
    return function cleanup() {
      document.body.className = '';
    };
  });
  useEffect(() => {
    const checkPlanSelectionRequired = async () => {
      try {
        const response = await JwtService.get(`/api/v1/businessPartner/activateBusinessPartnerUsers/${token}/verify`);
        
        if (response.data.isValid && response.data.planSelectionRequired) {
          setTimeout(() => {
            window.location.href = `/choose-plan/${token}`;
          }, 2000);
        } else {
          buyPlan();
        }
      } catch (error) {
        toast.error('An error occurred while verifying the activation.');
        setTimeout(() => {
          window.location.href = "/login";
        }, 2000);
      }
    };

    checkPlanSelectionRequired();
  }, []);


  const buyPlan = async (plan) => {
    try {
      const res = await JwtService.put(
        `/api/v1/businessPartner/activateBusinessPartnerUsers/${token}`,
        { plan }
      );
      if (res.codes === 200) {
        toast.success(res.message);
        localStorage.removeItem('plan');
        setTimeout(() => {
          window.location.href = "/login"; // Use window.location.href to navigate
        }, 2000);
      } else if (res.codes === 400) {
        toast.error('Something went wrong');
      }
    } catch (error) {
      toast.error('An error occurred');
    }
  };
  return (
    <React.Fragment>
      <div className="pt-sm-5">
        <Container>
          <Row>
            <div className="col-12 text-center">
              <div className="home-wrapper">
                <Link to="/" className="mb-5 d-block auth-logo">
                  <img
                    src={logoActive}
                    alt=""
                    height="50"
                    className="logo logo-dark"
                  />
                </Link>
                {/* <Row>
                  <Col>
                    <div class="sec-title text-center">
                      <span class="title">Get plan</span>
                      <h3>Choose a Plan</h3>
                    </div>
                  </Col>
                </Row> */}
                <Row className="justify-content-md-center">
                  <Col lg={6}>
                    <Alert
                      color="success"
                      isOpen={visible}
                      toggle={() => setAlert(false)}
                      fade={false}
                    >
                      {alertMessage}
                    </Alert>
                  </Col>
                </Row>
                <Row>
                  {loader && (
                    <div id="preloader" className="position-relative">
                      <div id="status">
                        <div className="spinner">
                          <i className="uil-shutter-alt spin-icon"></i>
                        </div>
                      </div>
                    </div>
                  )}
                </Row>
                {/* <div className="row justify-content-md-center">
                  {plans.length > 0 &&
                    plans.map((plan) => {
                      return (
                        <div
                          className="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
                          data-wow-delay="400ms"
                        >
                          <div className="inner-box">
                            <div className="price-box">
                              <div className="title">Flat Fee Charging</div>
                              <h4 className="price">
                                {new Intl.NumberFormat("ja-JP", {
                                  style: "currency",
                                  currency: "GBP",
                                }).format(plan.planPrice)}
                              </h4>
                            </div>
                            <ul className="features">
                              <li className="true">Conference plans</li>
                              <li className="true">Free Lunch And Coffee</li>
                              <li className="true">Certificate</li>
                              <li className="true">Easy Access</li>
                              <li className="false">Free Contacts</li>
                            </ul>
                            <div className="text-center">
                              <a
                                className="btn btn-primary"
                                onClick={() => buyPlan(plan.id)}
                              >
                                BUY plan
                              </a>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div> */}
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
    // <React.Fragment>
    //   <div className="my-5 pt-sm-5">
    //     <Container>
    //       <Row>
    //         <div className="col-12 text-center">
    //           <div className="home-wrapper">
    //             <Row>
    //               <Col lg={12}>
    //                 <div className="text-center">
    //                   <Link to="/" className="mb-5 d-block auth-logo">
    //                     <img
    //                       src={logoActive}
    //                       alt=""
    //                       height="50"
    //                       className="logo logo-dark"
    //                     />
    //                     {/* <img src={logolight} alt="" height="22" className="logo logo-light" /> */}
    //                   </Link>
    //                 </div>
    //               </Col>
    //             </Row>
    //             <Row className="justify-content-center">
    //               <Col sm={12}>
    //                 <h4 className="my-3">
    //                   {isVerifying ? "Verifying User..." : ""}
    //                 </h4>
    //               </Col>
    //             </Row>
    //             <Row>
    //               <Col>
    //                 <Alert
    //                   color="success"
    //                   isOpen={showAlert}
    //                   toggle={() => setAlert(false)}
    //                   fade={false}
    //                 >
    //                   {alertMessage}
    //                 </Alert>
    //                 {errorMessage && (
    //                   <Alert color="danger" fade={false}>
    //                     {errorMessage}
    //                   </Alert>
    //                 )}
    //               </Col>
    //             </Row>
    //           </div>
    //         </div>
    //       </Row>
    //     </Container>
    //   </div>
    // </React.Fragment>
  );
};

VerifyUser.propTypes = {
  history: PropTypes.object,
};

export default VerifyUser;
